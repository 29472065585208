import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import {baseUrl } from "../../../comman/config";
import { useNavigate, Link } from 'react-router-dom';

export default function Form({detail}) {
   
    const [formData, setFormData] = useState({
        name : '',
        top_dia : '',
        total_height : '',
        empty_weight: 0,
        used_for: '',
        total_volume: 0
    });
    const [validationErrors, setValidationErrors] = useState({
        name: false,
        // top_dia: false,
        // total_height: false,

    });
    const [popupOpen , setPopupOpen] = useState(false);
    const formRef = useRef(null);
    
    
    const navigate = useNavigate();
    
    useEffect(() => {
        if(detail){
            setFormData({
                name: detail[0].name || '',
                top_dia: detail[0].top_dia || '',
                total_height: detail[0].total_height || '',
                empty_weight: detail[0].empty_weight || 0,
                used_for: detail[0].used_for || '',
                total_volume: detail[0].total_volume || 0

            });
        }
    }, [detail]);



    const handleSubmit = async (e) => {
        e.preventDefault();
        const newValidationErrors = {
            name: formData.name === '',
            // top_dia: formData.top_dia === '',
            // total_height : formData.total_height === '',
        };
        setValidationErrors(newValidationErrors);
        const hasErrors = Object.values(newValidationErrors).some((error) => error);
        console.log(formData);
        if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }else {
            let url;
            try {
                setPopupOpen(true);
                if(detail){
                    url = `vessel_master/edit/${detail[0].id}`;
                }else{
                    url = "vessel_master/add";
                }
                await axios.post(`${baseUrl}/${url}`,{
                    formData: formData,
                });
                setPopupOpen(false);
                navigate("/vessel_master/list");
            } catch (error) {

                setPopupOpen(false);
                console.log(error);
                
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
    
            }));
      
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

    useEffect(() => {
        if(formData.top_dia && formData.total_height){
            const rad = formData.top_dia / 2;
            const volume = (3.14 * rad * rad * formData.total_height) / 1000;
            
            setFormData(prevData => ({ ...prevData, total_volume: volume.toFixed(2) }));
        }
    }, [formData.top_dia, formData.total_height]);

  return (
 
 <form ref={formRef}>
                <div className="space-y-12">
                    <div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Vessel Name</label>
                                <div className="mt-2">
                                    <input
                                      type="text"
                                      name="name"
                                      id="name"
                                      placeholder='Vessel Name'
                                      value={formData.name}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.name ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.name && <div className="text-red-500">Please enter a vessel name.</div>}
                            </div>
                            
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                        <div className="sm:col-span-2">
                                <label htmlFor="top_dia" className="block text-sm font-medium leading-6 text-gray-900">Top Dia (cms)</label>
                                <div className="mt-2">
                                    <input
                                      id="top_dia"
                                      name="top_dia"
                                      type="number"
                                      step="0.01"
                                      placeholder='Top Dia (cms)'
                                      value={formData.top_dia}
                                      onChange={handleInputChange}
                                      onWheel={(e) => e.target.blur()}
                                      onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                                      className={`block w-full rounded-md border ${validationErrors.top_dia ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.top_dia && <div className="text-red-500">Please enter a top dia.</div>}
                            </div>
                        </div>
                        
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="total_height" className="block text-sm font-medium leading-6 text-gray-900">Total Height (cms.)</label>
                                <div className="mt-2">
                                    
                                    <input 
                                      id="total_height"
                                      name="total_height"
                                      type="number"
                                      step="0.01"
                                      placeholder='Total Height (cms.)'
                                      value={formData.total_height}
                                      onChange={handleInputChange}
                                      onWheel={(e) => e.target.blur()}
                                      onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                                      className={`block w-full rounded-md border ${validationErrors.total_height ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                        
                                    />
                                    
                                </div>
                                {validationErrors.total_height && <div className="text-red-500">Please enter total height.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="empty_weight" className="block text-sm font-medium leading-6 text-gray-900">Empty Weight (kgs.)</label>
                                <div className="mt-2">
                                    
                                    <input 
                                      id="empty_weight"
                                      name="empty_weight"
                                      type="number"
                                      step="0.01"
                                      placeholder='Empty Weight (kgs.)'
                                      value={formData.empty_weight}
                                      onChange={handleInputChange}
                                      onWheel={(e) => e.target.blur()}
                                      onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                                      className={`block w-full rounded-md border ${validationErrors.empty_weight ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                        
                                    />
                                    
                                </div>
                                {validationErrors.empty_weight && <div className="text-red-500">Please enter empty weight.</div>}
                            </div>
                        </div>
                        {detail && <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="total_volume" className="block text-sm font-medium leading-6 text-gray-900">Total volume (ltrs.)</label>
                                <div className="mt-2">
                                    
                                    <input 
                                      id="total_volume"
                                      name="total_volume"
                                      type="number"
                                      step="0.01"
                                      disabled
                                      value={formData.total_volume}
                                      onChange={handleInputChange}
                                      onWheel={(e) => e.target.blur()}
                                      onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                                      className={`block w-full rounded-md border ${validationErrors.total_volume ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                        
                                    />
                                    
                                </div>
                            </div>
                        </div>}
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="used_for" className="block text-sm font-medium leading-6 text-gray-900">Used For</label>
                                <div className="mt-2">
                                    
                                    <input 
                                      id="used_for"
                                      name="used_for"
                                      type="text"
                                      placeholder='Used for'
                                      value={formData.used_for}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.used_for ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                        
                                    />
                                    
                                </div>
                                {validationErrors.used_for && <div className="text-red-500">Please enter value.</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <Link to="/vessel_master/list">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                    </Link>
                    <button
                    onClick={handleSubmit}
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                </div>
            </form>
            )
        }