import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import {baseUrl } from "../../../comman/config";
import { useNavigate, Link } from 'react-router-dom';

export default function Form({detail}) {
   
    const [formData, setFormData] = useState({
        name : ''
    });
    const [validationErrors, setValidationErrors] = useState({
        name: false,
    });
    
    const [popupOpen , setPopupOpen] = useState(false);
    const formRef = useRef(null);
    
    
    const navigate = useNavigate();
   

    useEffect(() => {
        if(detail){
            setFormData({
                name: detail[0].name || ''
            });
        }
        // console.log(detail[0]);
    }, [detail]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const gujaratiRegex = /^[\u0A80-\u0AFF\s]+$/;
        // const gujaratiRegex = /^[\u0A80-\u0AFF\s()+\-]*$/;
        const newValidationErrors = {
            name: formData.name === ''
        };
        setValidationErrors(newValidationErrors);
        const hasErrors = Object.values(newValidationErrors).some((error) => error);
        console.log(formData);
        if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }else {
            let url;
            try {
                setPopupOpen(true);
                if(detail){
                    url = `store/edit/${detail[0].id}`;
                }else{
                    url = "store/add";
                }
                await axios.post(`${baseUrl}/${url}`,{
                    formData: formData,
                });
                setPopupOpen(false);
                navigate("/store/list");
            } catch (error) {

                setPopupOpen(false);
                console.log(error);
                
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
    
            }));
      
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

  return (
 
 <form ref={formRef}>
                <div className="space-y-12">
                    <div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name</label>
                                <div className="mt-2">
                                    <input
                                      type="text"
                                      name="name"
                                      id="name"
                                      placeholder='Name'
                                      value={formData.name}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.name ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.name && <div className="text-red-500">Please enter name.</div>}
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <Link to="/store/list">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                    </Link>
                    <button
                    onClick={handleSubmit}
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                </div>
            </form>
            )
        }