import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../comman/config";
import Select from "react-select";
import { useNavigate, Link } from "react-router-dom";

export default function Form({ detail }) {
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    empty_weight: "",
    length: 0,
    width: 0,
    height: 0,
    length_between_wheels: 0,
    width_between_wheels: 0,
    photo: "",
  });
  const [fileChanged, setFileChanged] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    name: false,
    empty_weight: false,
    // bowl_diameter_bottom: false,
    // bowl_diameter_marked_ht: false,
    // bowl_total_height: false,
    // bowl_top_dia: false
  });
  const [popupOpen, setPopupOpen] = useState(false);
  const [trollyTypeList, setTrollyTypeList] = useState([]);
  const formRef = useRef(null);

  const navigate = useNavigate();
  useEffect(() => {
    getTrollyTypeList();
  }, []);

  useEffect(() => {
    if (detail && trollyTypeList) {
      const typeItem = detail[0].type;
      // console.log(typeId);
      // const typeItem =  trollyTypeList.find((item) => item.id == typeId);
      const typeObject = typeItem
        ? {
            value: typeItem.id,
            label: `${typeItem.name} (${typeItem.eng_name})`,
          }
        : { value: "", label: "" };
      setFormData({
        name: detail[0].name || "",
        type: typeObject || "",
        empty_weight: detail[0].empty_weight || "",
        length: detail[0].length || 0,
        width: detail[0].width || 0,
        height: detail[0].height || 0,
        length_between_wheels: detail[0].length_between_wheels || 0,
        width_between_wheels: detail[0].width_between_wheels || 0,
        photo: detail[0].photo || "",
      });
    }
  }, [detail, trollyTypeList]);

  const getTrollyTypeList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=trolly_type_master&condition=status=1`
    );
    setTrollyTypeList(response.data.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isFileValid =
      fileChanged && formData.photo && isValidFileType(formData.photo); // Implement isValidFileType function
    const isFileSizeValid =
      fileChanged && formData.photo && isValidFileSize(formData.photo); // Implement isValidFileSize function
    console.log(isFileValid);
    console.log(isFileSizeValid);
    console.log(formData.photo);
    const newValidationErrors = {
      name: formData.name === "",
      empty_weight: formData.empty_weight === "",
      photo:
        (fileChanged && formData.photo && !isFileValid) ||
        (fileChanged && formData.photo && !isFileSizeValid),
    };
    setValidationErrors(newValidationErrors);
    const hasErrors = Object.values(newValidationErrors).some((error) => error);
    console.log(formData);
    if (hasErrors) {
      // Find the first invalid input element
      const firstInvalidInput = formRef.current.querySelector(".invalid");
      console.log(firstInvalidInput);
      if (firstInvalidInput) {
        // Scroll to the first invalid input element
        firstInvalidInput.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    } else {
      let url;
      try {
        console.log(formData);
        setPopupOpen(true);
        if (detail) {
          url = `trolly_master/edit/${detail[0].id}`;
        } else {
          url = "trolly_master/add";
        }

        const formDataToSend = new FormData();
        formDataToSend.append("name", formData.name);
        formDataToSend.append("type", formData.type.value); // Assuming you're using react-select and formData.type is an object
        formDataToSend.append("empty_weight", formData.empty_weight);
        formDataToSend.append("length", formData.length);
        formDataToSend.append("width", formData.width);
        formDataToSend.append("height", formData.height);
        formDataToSend.append(
          "length_between_wheels",
          formData.length_between_wheels
        );
        formDataToSend.append(
          "width_between_wheels",
          formData.width_between_wheels
        );
        formDataToSend.append("photo", formData.photo);

        await axios.post(`${baseUrl}/${url}`, formDataToSend);
        setPopupOpen(false);
        navigate("/trolly_master/list");
      } catch (error) {
        setPopupOpen(false);
        console.log(error);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    console.log(e.target);
    if (type === "file") {
      setFileChanged(true);
      // For file inputs, use the first file from the files list
      const file = e.target.files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleSelectChange = (data, option) => {
    setFormData((prevData) => ({
      ...prevData,
      [option.name]: data,
    }));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [option.name]: false,
    }));
  };

  const isValidFileType = (file) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    return allowedExtensions.test(file.name);
  };

  const isValidFileSize = (file) => {
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    return file.size <= maxSizeInBytes;
  };

  return (
    <form ref={formRef}>
      <div className="space-y-12">
        <div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Trolly Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Trolly Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={`block w-full rounded-md border ${
                    validationErrors.name ? "border-red-500" : "border-gray-300"
                  } py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                />
              </div>
              {validationErrors.name && (
                <div className="text-red-500">Please enter a trolly name.</div>
              )}
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
            <div className="sm:col-span-2">
              <label
                htmlFor="type"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Category
              </label>
              <div className="mt-2">
                {/* <input
                                      id="type"
                                      name="type"
                                      type="text"
                                      value={formData.type}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.bowl_color ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    /> */}
                <Select
                  classNamePrefix="select"
                  value={formData.type}
                  name="type"
                  onChange={(selectedOption, name) => {
                    handleSelectChange(selectedOption, name);
                  }}
                  options={trollyTypeList.map((item) => ({
                    value: item.id,
                    label: `${item.name} (${item.eng_name})`,
                  }))}
                  filterOption={(option, inputValue) => {
                    return (
                      option.data.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase()) ||
                      option.data.eng_name
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    );
                  }}
                />
              </div>
              {validationErrors.type && (
                <div className="text-red-500">Please enter a type.</div>
              )}
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
            <div className="sm:col-span-2">
              <label
                htmlFor="empty_weight"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Empty Weight (kgs.)
              </label>
              <div className="mt-2">
                <input
                  id="empty_weight"
                  name="empty_weight"
                  type="number"
                  placeholder=" Empty Weight (kgs.)"
                  step="0.01"
                  value={formData.empty_weight}
                  onChange={handleInputChange}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                  className={`block w-full rounded-md border ${
                    validationErrors.empty_weight
                      ? "border-red-500"
                      : "border-gray-300"
                  } py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                />
              </div>
              {validationErrors.empty_weight && (
                <div className="text-red-500">Please enter empty weight.</div>
              )}
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
            <div className="sm:col-span-2">
              <label
                htmlFor="length"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Length (cms.)
              </label>
              <div className="mt-2">
                <input
                  id="length"
                  name="length"
                  type="number"
                  step="0.01"
                  placeholder="Length (cms.)"
                  value={formData.length}
                  onChange={handleInputChange}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                  className={`block w-full rounded-md border ${
                    validationErrors.length
                      ? "border-red-500"
                      : "border-gray-300"
                  } py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                />
              </div>
              {validationErrors.length && (
                <div className="text-red-500">Please enter length.</div>
              )}
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
            <div className="sm:col-span-2">
              <label
                htmlFor="width"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Breadth (cms.)
              </label>
              <div className="mt-2">
                <input
                  id="width"
                  name="width"
                  type="number"
                  step="0.01"
                  placeholder="Breadth (cms.)"
                  value={formData.width}
                  onChange={handleInputChange}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                  className={`block w-full rounded-md border ${
                    validationErrors.width
                      ? "border-red-500"
                      : "border-gray-300"
                  } py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                />
              </div>
              {validationErrors.width && (
                <div className="text-red-500">Please enter width.</div>
              )}
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
            <div className="sm:col-span-2">
              <label
                htmlFor="height"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Height (cms.)
              </label>
              <div className="mt-2">
                <input
                  id="height"
                  name="height"
                  type="number"
                  placeholder="Height (cms.)"
                  step="0.01"
                  value={formData.height}
                  onChange={handleInputChange}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                  className={`block w-full rounded-md border ${
                    validationErrors.height
                      ? "border-red-500"
                      : "border-gray-300"
                  } py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                />
              </div>
              {validationErrors.height && (
                <div className="text-red-500">Please enter height.</div>
              )}
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
            <div className="sm:col-span-2">
              <label
                htmlFor="length_between_wheels"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Wheel Length (cms.)
              </label>
              <div className="mt-2">
                <input
                  id="length_between_wheels"
                  name="length_between_wheels"
                  type="number"
                  step="0.01"
                  placeholder="Wheel Length (cms.)"
                  value={formData.length_between_wheels}
                  onChange={handleInputChange}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                  className={`block w-full rounded-md border ${
                    validationErrors.length_between_wheels
                      ? "border-red-500"
                      : "border-gray-300"
                  } py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                />
              </div>
              {validationErrors.length_between_wheels && (
                <div className="text-red-500">Please enter wheel length.</div>
              )}
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
            <div className="sm:col-span-2">
              <label
                htmlFor="width_between_wheels"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Wheel Breadth (cms.)
              </label>
              <div className="mt-2">
                <input
                  id="width_between_wheels"
                  name="width_between_wheels"
                  type="number"
                  placeholder="Wheel Breadth (cms.)"
                  step="0.01"
                  value={formData.width_between_wheels}
                  onChange={handleInputChange}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                  className={`block w-full rounded-md border ${
                    validationErrors.width_between_wheels
                      ? "border-red-500"
                      : "border-gray-300"
                  } py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                />
              </div>
              {validationErrors.width_between_wheels && (
                <div className="text-red-500">Please enter wheel width.</div>
              )}
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
            <div className="sm:col-span-2">
              <label
                htmlFor="photo"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Photo
              </label>
              <div className="mt-2">
                <input
                  id="photo"
                  name="photo"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  //   value={formData.photo}
                  onChange={handleInputChange}
                  className={`block w-full rounded-md border ${
                    validationErrors.photo
                      ? "border-red-500"
                      : "border-gray-300"
                  } py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                />
              </div>
              {formData.photo && (
                <img
                  src={`${baseUrl}/static/${formData.photo}`}
                  alt="Uploaded"
                />
              )}
              {validationErrors.photo && (
                <div className="text-red-500">Please select valid image.</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <Link to="/trolly_master/list">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
        </Link>
        <button
          onClick={handleSubmit}
          type="submit"
          className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}
