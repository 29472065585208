import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import {baseUrl } from "../../../comman/config";
import { useNavigate, Link } from 'react-router-dom';

export default function Form({detail}) {
   
    const [formData, setFormData] = useState({
        ingredient_eng : '',
        ingredient_guj : '',
        ingredient_category : '',
        ingredient_unit : '',
        store: '',
        wastage: 0
    });
    const [validationErrors, setValidationErrors] = useState({
        ingredient_eng: false,
        ingredient_guj: false,
        ingredient_category: false,
        ingredient_unit: false,
        store: false

    });
    const [ingredientCategory, setIngredientCategory] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [popupOpen , setPopupOpen] = useState(false);
    const formRef = useRef(null);
    
    
    const navigate = useNavigate();
    useEffect(() => {
        getIngredientCategory();
        getStoreList();
        getUnitList();
    }, []);

    useEffect(() => {
        if(detail){
            setFormData({
                ingredient_eng: detail[0].eng_name || '',
                ingredient_guj: detail[0].ingredient || '',
                ingredient_category: detail[0]?.category?.id || '',
                ingredient_unit: detail[0]?.unit?.id || '',
                store: detail[0]?.store?.id || '',
                wastage : detail[0]?.wastage || 0,
            });
        }
        // console.log(detail[0]);
    }, [detail]);

    const getIngredientCategory = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=ingredients_category&condition=status=1`);
        setIngredientCategory(response.data.data);
    }

    const getStoreList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=store&condition=status=1`);
        setStoreList(response.data.data);
    }
    
    const getUnitList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=units`);
        setUnitList(response.data.data);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const gujaratiRegex = /^[\u0A80-\u0AFF\s]+$/;
        const gujaratiRegex = /^[\u0A80-\u0AFF\s()+\-]*$/;
        const newValidationErrors = {
            ingredient_eng: formData.ingredient_eng === '',
            ingredient_guj: formData.ingredient_guj === '' || !gujaratiRegex.test(formData.ingredient_guj),
            ingredient_category : formData.ingredient_category === '',
            store : formData.store === '',
            ingredient_unit : formData.ingredient_unit === '',
        };
        setValidationErrors(newValidationErrors);
        const hasErrors = Object.values(newValidationErrors).some((error) => error);
        console.log(formData);
        if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }else {
            let url;
            try {
                setPopupOpen(true);
                if(detail){
                    url = `ingredients/edit/${detail[0].id}`;
                }else{
                    url = "ingredients/add";
                }
                await axios.post(`${baseUrl}/${url}`,{
                    formData: formData,
                });
                setPopupOpen(false);
                navigate("/ingredients/list");
            } catch (error) {

                setPopupOpen(false);
                console.log(error);
                
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
    
            }));
      
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

  return (
 
 <form ref={formRef}>
                <div className="space-y-12">
                    <div>
                        <div className="mt-10 space-y-8 lg:space-y-0 lg:flex lg:gap-x-5 lg:flex-wrap">
                            <div className="sm:col-span-2">
                                <label htmlFor="ingredient_eng" className="block text-sm font-medium leading-6 text-gray-900">Ingredient Name (Eng)</label>
                                <div className="mt-2">
                                    <input
                                      type="text"
                                      name="ingredient_eng"
                                      id="ingredient_eng"
                                      placeholder='Ingredient Name (Eng)'
                                      value={formData.ingredient_eng}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.ingredient_eng ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.ingredient_eng && <div className="text-red-500">Please enter a english name.</div>}
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="ingredient_guj" className="block text-sm font-medium leading-6 text-gray-900">Ingredient Name (Guj)</label>
                                <div className="mt-2">
                                    <input
                                      id="ingredient_guj"
                                      name="ingredient_guj"
                                      type="text"
                                      placeholder='Ingredient Name (Guj)'
                                      value={formData.ingredient_guj}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.ingredient_guj ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.ingredient_guj && <div className="text-red-500">Please enter a gujarati name.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="ingredient_category" className="block text-sm font-medium leading-6 text-gray-900">Ingredient Category</label>
                                <div className="mt-2">
                                    
                                    <select 
                                      id="ingredient_category"
                                      name="ingredient_category"
                                      type="text"
                                      value={formData.ingredient_category}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.ingredient_category ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}>
                                        <option value="">Select a Category</option>
                                        {ingredientCategory.map((item) => (
                                            <option value={item.id} key={item.id}>{item.name} ({item.eng_name})</option>
                                        ))};
                                    </select>
                                    
                                </div>
                                {validationErrors.ingredient_category && <div className="text-red-500">Please select a category.</div>}
                            </div>
                        </div>
                       <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="store" className="block text-sm font-medium leading-6 text-gray-900">Store Type</label>
                                <div className="mt-2">
                                <select 
                                      id="store"
                                      name="store"
                                      type="text"
                                      value={formData.store}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.store ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}>
                                        <option value="">Select a store</option>
                                        {storeList.map((item) => (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        ))};
                                    </select>
                                   
                                </div>
                                {validationErrors.store && <div className="text-red-500">Please select a store.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="ingredient_unit" className="block text-sm font-medium leading-6 text-gray-900">Unit</label>
                                <div className="mt-2">
                                <select 
                                      id="ingredient_unit"
                                      name="ingredient_unit"
                                      type="text"
                                      value={formData.ingredient_unit}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.ingredient_unit ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}>
                                        <option value="">Select a Unit</option>
                                        {unitList.map((item) => (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        ))};
                                    </select>
                                   
                                </div>
                                {validationErrors.ingredient_unit && <div className="text-red-500">Please select a unit.</div>}
                            </div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
                            <div className="sm:col-span-2">
                                <label htmlFor="wastage" className="block text-sm font-medium leading-6 text-gray-900">Avg. Wastage (%)</label>
                                <div className="mt-2">
                                    <input
                                      type="number"
                                      name="wastage"
                                      id="wastage"
                                      placeholder='Avg. Wastage (%)'
                                      value={formData.wastage}
                                      onChange={handleInputChange}
                                      onWheel={(e) => e.target.blur()}
                                      className={`block w-full rounded-md border py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <Link to="/ingredients/list">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                    </Link>
                    <button
                    onClick={handleSubmit}
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                </div>
            </form>
            )
        }