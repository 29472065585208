// ModalContent.js
import React, {useState} from "react";
import Select from "react-select";

const ModalContent = ({ showModal, setShowModal, ingredientList, handleAddIngredient, ingredientDetail }) => {
  const [selectedItem, setSelectedItem] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  const handelChangeSelect = (data) => {
    
    const found = ingredientDetail.some(item => item.ingredient_id === data.value);
    if (found) {
      alert('Error: ingredient already exists');
      setDisableButton(true);
    } else {
      setSelectedItem(data)
      setDisableButton(false);
    }
    
  }
  const handleAddItem =  () => {
  // console.log(ingredientList[0]);
  handleAddIngredient(selectedItem);
  setShowModal(false)
  };
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Add Ingredient
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                
                <div className="relative p-6 flex-auto">
                <Select
                    
                    classNamePrefix="select"
                    className='basic-multi-select'
                    name="item"
                    onChange={(selectedOptions) => handelChangeSelect(selectedOptions)}
                    options={ingredientList.map(item => ({
                        value: item.id,
                        label: `${item.ingredient} ${item.eng_name ? `(${item.eng_name})` : ''}`,
                        name: item.ingredient,
                        data: item,
                        
                    }))}
                    placeholder="Item List"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    disabled={disableButton}
                    onClick={() => handleAddItem()}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ModalContent;
