import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import {baseUrl } from "../../../comman/config";
import { useNavigate, Link } from 'react-router-dom';

export default function Form({detail, page}) {
   
    const [formData, setFormData] = useState({
        category_eng : '',
        category_guj : ''
    });
    const [validationErrors, setValidationErrors] = useState({
        category_eng: false,
        category_guj: false
    });
    
    const [popupOpen , setPopupOpen] = useState(false);
    const formRef = useRef(null);
    
    
    const navigate = useNavigate();
   

    useEffect(() => {
        if(detail){
            setFormData({
                category_eng: detail[0].eng_name || '',
                category_guj: detail[0].name || ''
            });
        }
        // console.log(detail[0]);
    }, [detail]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // const gujaratiRegex = /^[\u0A80-\u0AFF\s]+$/;
        const gujaratiRegex = /^[\u0A80-\u0AFF\s()+\-]*$/;
        let newValidationErrors = {
            category_eng: formData.category_eng === '',
            
        };
        if(page != "trolly_type"){
            newValidationErrors.category_guj = formData.category_guj === '' || !gujaratiRegex.test(formData.category_guj);
        }
    
        setValidationErrors(newValidationErrors);
        const hasErrors = Object.values(newValidationErrors).some((error) => error);
        console.log(formData);
        if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }else {
            let url;
            try {
                setPopupOpen(true);
                if(detail){
                    url = `${page}/category/edit/${detail[0].id}`;
                }else{
                    url = `${page}/category/add`;
                }
                await axios.post(`${baseUrl}/${url}`,{
                    formData: formData,
                });
                setPopupOpen(false);
                if(page == "trolly_type"){
                    navigate(`/${page}/list`);
                }else{
                    navigate(`/${page}_category/list`);
                }
                
            } catch (error) {

                setPopupOpen(false);
                console.log(error);
                
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
    
            }));
      
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

  return (
 
 <form ref={formRef}>
                <div className="space-y-12">
                    <div>
                        <div className="mt-10 space-y-8 lg:space-y-0 lg:flex lg:gap-x-5 lg:flex-wrap">
                            <div className="sm:col-span-2">
                                <label htmlFor="category_eng" className="block text-sm font-medium leading-6 text-gray-900">Category Name (Eng)</label>
                                <div className="mt-2">
                                    <input
                                      type="text"
                                      name="category_eng"
                                      id="category_eng"
                                      placeholder='Category Name (Eng)'
                                      value={formData.category_eng}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.category_eng ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.category_eng && <div className="text-red-500">Please enter a english name.</div>}
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="category_guj" className="block text-sm font-medium leading-6 text-gray-900">Category Name (Guj)</label>
                                <div className="mt-2">
                                    <input
                                      id="category_guj"
                                      name="category_guj"
                                      type="text"
                                      placeholder='Category Name (Guj)'
                                      value={formData.category_guj}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.category_guj ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.category_guj && <div className="text-red-500">Please enter a gujarati name.</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    {/* <Link to="/ingredients_category/list">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                    </Link> */}
                    <button
                    onClick={handleSubmit}
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                </div>
            </form>
            )
        }