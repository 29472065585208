
import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../../comman/config";
import { useLocation, Link, useNavigate} from 'react-router-dom';
import ReceipeForm from "../../../components/Form/Master/ReceipeForm";
import * as XLSX from 'xlsx';

export default function EditMenuitem() {

    const [ingredientList, setIngredientList] = useState([]);
    const location = useLocation();
    const detail = location.state;
    const navigate = useNavigate();

    const handleExport = async() => {
        const response = await axios.post(`${baseUrl}/receipe/export`, {menu_item_id : detail[0].id});

        const data = response.data.data;

        const itemName = detail[0]?.item_name;
        const engName = detail[0]?.eng_name;
        const itemDisplayName = itemName || engName ? `${itemName ?? ''}${engName ? ` (${engName})` : ''}`.trim() : '-';
        // Separate the data by maharaj_name
        const separatedData = data.reduce((acc, curr) => {
            const { maharaj_name } = curr;
        
            if (!acc[maharaj_name]) {
                acc[maharaj_name] = [];
            }
            acc[maharaj_name].push(curr);
            return acc;
        }, {});

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Header row for the ingredients data
        const header = ["Id", "Ingredients", "Qty/100kg", "Unit"];

        // Item name array
        const itemNameArray = ['Item Name:', itemDisplayName];

        // Add separate sheets for each maharaj_name
        for (const [maharaj, ingredients] of Object.entries(separatedData)) {
            // Convert the ingredients to a 2D array format (rows and columns) for the sheet
            const maharajArray = ingredients.map((ingredient, index) => [
                index+1,
                `${ingredient?.ingredient || ingredient?.eng_name ? `${ingredient?.ingredient ?? ''}${ingredient?.eng_name ? ` (${ingredient.eng_name})` : ''}`.trim() : '-'}`,
                ingredient.qty,
                ingredient.unit
            ]);
        
            // Create a final array combining item name row, maharaj data, and header
            const finalData = [
                itemNameArray,    // Item name row at the top
                [],               // Empty row for spacing
                header,
                ...maharajArray,
            ];
        
            // Convert finalData array to sheet format
            const ws = XLSX.utils.aoa_to_sheet(finalData);
            XLSX.utils.book_append_sheet(wb, ws, maharaj);
        }

        // Write the Excel file
        const fileName = `${itemName}_${engName}_Recipe.xlsx`.replace(/\s+/g, '_');
        XLSX.writeFile(wb, fileName);
       
    }

    const handleExportProcess = async() => {
        const condition = `menu_item_id = ${detail[0].id} AND maharaj = ${detail[0].maharaj?.id}`;
        const response = await axios.get(`${baseUrl}/comman/list?table=receipe_master_process&condition=${condition}`);

        const processList = response.data.data;
        const itemName = detail[0]?.item_name;
        const engName = detail[0]?.eng_name;
        const maharajName = detail[0].maharaj?.maharaj_name ?? "";
        const itemDisplayName = itemName || engName ? `${itemName ?? ''}${engName ? ` (${engName})` : ''}`.trim() : '-';

        const header = ["Step", "Process"];
        const itemNameArray = ["Item Name", itemDisplayName];
        const maharajArray = ["Maharaj Name", maharajName];
        console.log(processList);
        // Prepare data for export
        const data = [
            itemNameArray, // Item name row
            maharajArray,
            [], // Empty row for spacing
            header, // Header row
            ...processList.map((item, index) => {
                return [
                    index + 1,
                    item?.process ?? ""
                ];
            })
        ];

        // Create a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        
        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Recipe Process');

        // Generate a file and trigger download
        const fileName = `${itemName}_${engName}_Process.xlsx`.replace(/\s+/g, '_');
        XLSX.writeFile(workbook, fileName);
    }
    const handelIngredientList = (list) => {
        setIngredientList(list);
    }

    const handleDuplicate = () => {
        navigate(`/receipe/add`, { state: detail });
    }

  return (
    <div className="p-4">
        <div className="flex justify-between items-center py-4 px-6">
            <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">Recipe Edit</h1>
            <div>
            <button onClick={handleDuplicate} className="px-4 py-2 bg-blue-500 text-white rounded-md placeholder:text-sm mr-2">Duplicate</button>
                <button onClick={handleExport} className="px-4 py-2 bg-blue-500 text-white rounded-md placeholder:text-sm mr-2">Export Recipe</button>
                <button onClick={handleExportProcess} className="px-4 py-2 bg-blue-500 text-white rounded-md placeholder:text-sm mr-2">Export Process</button>
                <a href="/ingredients/add" target="_blank">
                    <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Add Ingredient</button>
                </a>
                <Link to="/receipe/list">
                    <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Back</button>
                </Link>
            </div>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg px-4 py-2">
           <ReceipeForm detail={detail} handelIngredientList={handelIngredientList} />
        </div>
    </div>
  )
}
