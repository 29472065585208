import React, { useContext, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation, Navigate } from 'react-router-dom';
import TitleContext from './TitleContext';

const HelmetWrapper = ({ children, title, route }) => {
  const { setTitle } = useContext(TitleContext);
  const location = useLocation();
  // console.log(route);

  useEffect(() => {
    setTitle(title);
    document.title = title;
  }, [title, location.pathname, setTitle]);

  const allowedRoles = route?.allowedRoles || [];
  const userRole = parseInt(localStorage.getItem('userRole'), 10); // Parse as integer

  const canAccessRoute = allowedRoles.length === 0 || allowedRoles.includes(userRole);
  // console.log(allowedRoles);
  if (route.path === "/login") {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </>
    );
  }else if (canAccessRoute) {
    // Render the route if user has access
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </>
    );
  }else {
    // Redirect or show access denied message if user does not have access
    return <Navigate to="/login" />;
  }
  
};

export default HelmetWrapper;
