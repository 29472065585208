import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import {baseUrl, issueStatusList, requirementStatusList,orderStatusList,purchaseStatusList,returnReceiptStatusList, addDays } from "../../comman/config";

const MainRequirementFilter = ({ handleFilterChange, page }) => {
    const today = new Date().toISOString().split('T')[0];
    const [fromDate, setFromDate] = useState(new Date().toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(addDays(new Date(), 1).toISOString().split('T')[0]);
    const [session, setSession] = useState("");
    
    const [requirementStatus, setRequirementStatus] = useState(requirementStatusList[1]);
    const [orderStatus, setOrderStatus] = useState(orderStatusList[1]);
    const [purchaseStatus, setPurchaseStatus] = useState(purchaseStatusList[1]);
    const [receiptStatus, setReceiptStatus] = useState(returnReceiptStatusList[1]);
    const [issueStatus, setIssueStatus] = useState(issueStatusList[1])
    const [orderId, setOrderId] = useState("");
    const [ingredientList, setIngredientList] = useState([]);
    const [filter, setFilter] = useState(false);
    const [sessionList, setSessionList] = useState([]);
    const [orderIdList, setOrderIdList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [item, setItem] = useState([]);
    const [ingredient, setIngredient] = useState([]);
    

    useEffect(() => {
        getSessionList();
        getItemList();
        getIngredientList();
        getOrderIdList();
    }, []);

    const getSessionList =  async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=session_master&condition=status=1`,
            { headers: { 'Cache-Control': 'no-cache' } }
        );
        setSessionList(response.data.data);
    }

    const getItemList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=item_master&condition=status=1`);
        const itemList = response.data.data;
        setItemList(itemList);
    }

    const getIngredientList = async () => {
        const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`);
        const list = response.data.data.filter((item) => item.status == 1 && item?.store?.id == 3);
        setIngredientList(list);
    };

    const getOrderIdList =  async () => {
        const condition = `order_id IS NOT NULL GROUP BY order_id`;
        const response = await axios.get(`${baseUrl}/comman/list?table=item_requirement_summary&condition=${condition}`,
            { headers: { 'Cache-Control': 'no-cache' } }
        );
        setOrderIdList(response.data.data);
    }
    const handleDateChange = (e) => {
        const {name, value} = e.target;
        if(name == "from_date"){
            setFromDate(value);
        }else if(name == "to_date"){
            setToDate(value);
        }
    };

    
    const handleSelectChange = (data, option) => {
        if(option.name == 'session'){
            setSession(data);
        }else if(option.name == 'requirement_status'){
            setRequirementStatus(data);
        }else if(option.name == 'order_status'){
            setOrderStatus(data);
        }else if(option.name == 'order_id'){
            setOrderId(data);
        }else if(option.name == 'purchase_status'){
            setPurchaseStatus(data);
        }else if(option.name == 'receipt_status'){
            setReceiptStatus(data);
        }else if(option.name == 'issue_status'){
            setIssueStatus(data)
        }
        
    };
    const handleChangeMultiSelect = (selected, name) => {
        if(name === 'item'){
            setItem(selected);
        }else if(name === "ingredient"){
            setIngredient(selected);
        }
        
    };

    const handleSubmit = () => {
        if(page == "issue"){
            handleFilterChange(fromDate, toDate, session, item, issueStatus);
        }
        if(page == "receipt"){
            handleFilterChange(fromDate, toDate, session, item, receiptStatus);
        }
        setFilter(true);
    };

    const handleClear = () => {
        
        setSession("");
        setFromDate(today);
        setToDate(today);
        setItem("");
        setRequirementStatus(requirementStatusList[1]);
        setOrderStatus(orderStatusList[1]);
        setPurchaseStatus(purchaseStatusList[1]);
        setReceiptStatus(returnReceiptStatusList[1]);
        setIssueStatus(issueStatusList[1])
        setOrderId("");
        setIngredient("");
        if(page == "issue"){
            handleFilterChange(today, today, "", "", issueStatusList[1]);
        }
        if(page == "receipt"){
            handleFilterChange(today, today, "", "", returnReceiptStatusList[1]);
        }
        
        setFilter(false);
    }

    return (
        <div className="pb-4 flex flex-column max-sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center">
            <div className='m-5 max-sm:m-1'>
                <input
                    type="date"
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="from_date"
                    onChange={handleDateChange}
                    value={fromDate}
                />
            </div>
            <div className='m-5 max-sm:m-1'>
                <input
                    type="date"
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="to_date"
                    onChange={handleDateChange}
                    value={toDate}
                />
            </div>

            {page == "issue" && 
            <>
                <div className='m-5'>
               
                    <Select
                        classNamePrefix="select"
                        value={session}
                        name="session"
                        onChange={(selectedOption, name) => {
                            handleSelectChange(selectedOption, name);
                        }}
                        options={sessionList.map(item => ({
                            value: item.id,
                            label: item.name
                        }))}
                        filterOption={(option, inputValue) => {
                            return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                        }}
                    />  
                </div>
                <div className='m-5'>
                    <Select
                        // isMulti
                        classNamePrefix="select"
                        className='basic-multi-select'
                        value={item}
                        name="item"
                        onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions, 'item')}
                        options={itemList.map(item => ({
                            value: item.id,
                            label: `${item.item_name} ${item.eng_name ? `(${item.eng_name})` : ''}`
                        }))}
                        placeholder="Item List"
                        filterOption={(option, inputValue) => {
                            return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                        }}
                    />  
                </div>

                <div className='m-5'>
                    <Select
                        classNamePrefix="select"
                        value={issueStatus}
                        name="issue_status"
                        onChange={(selectedOption, name) => {
                            console.log(selectedOption)
                            handleSelectChange(selectedOption, name);
                        }}
                        options={issueStatusList.map(item => ({
                            value: item.value,
                            label: item.label
                        }))}
                        filterOption={(option, inputValue) => {
                            return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                        }}
                    />  
                </div>
            </>}

            {page == "receipt" && 
            <>
                <div className='m-5'>
               
                    <Select
                        classNamePrefix="select"
                        value={session}
                        name="session"
                        onChange={(selectedOption, name) => {
                            handleSelectChange(selectedOption, name);
                        }}
                        options={sessionList.map(item => ({
                            value: item.id,
                            label: item.name
                        }))}
                        filterOption={(option, inputValue) => {
                            return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                        }}
                    />  
                </div>
                <div className='m-5'>
                    <Select
                        // isMulti
                        classNamePrefix="select"
                        className='basic-multi-select'
                        value={item}
                        name="item"
                        onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions, 'item')}
                        options={itemList.map(item => ({
                            value: item.id,
                            label: `${item.item_name} ${item.eng_name ? `(${item.eng_name})` : ''}`
                        }))}
                        placeholder="Item List"
                        filterOption={(option, inputValue) => {
                            return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                        }}
                    />  
                </div>

                <div className='m-5'>
                    <Select
                        classNamePrefix="select"
                        value={receiptStatus}
                        name="receipt_status"
                        onChange={(selectedOption, name) => {
                            console.log(selectedOption)
                            handleSelectChange(selectedOption, name);
                        }}
                        options={returnReceiptStatusList.map(item => ({
                            value: item.value,
                            label: item.label
                        }))}
                        filterOption={(option, inputValue) => {
                            return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                        }}
                    />  
                </div>
            </>}
            <div className='m-5'>
                <button
                    onClick={handleSubmit}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                    Submit
                </button>
                {filter && 
                 <button
                 onClick={handleClear}
                 className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
             >
                 Clear
             </button>}
            </div>
        </div>
    );
};

export {MainRequirementFilter};
