import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl, getDateWithDay } from "../../comman/config.js";
import { VegRequirementFilter } from "../../components/Filter/VegRequirementFilter.js";
import toast, { Toaster } from "react-hot-toast";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import IssueModal from "../../components/IssueModal.js";
import Select from 'react-select';
import Loader from "../../components/Loader.js";
import NoRecordsFound from "../../components/NoRecordsFound.js";
import { saveAs } from 'file-saver';

// import Pagination from "react-js-pagination";

function IssueNetList() {
  const today = new Date().toISOString().split("T")[0];
  const [requirementList, setRequirementList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [session, setSession] = useState({});
  const [netIssueStatus, setNetIssueStatus] = useState({});
  const [item, setItem] = useState({});
  const [kit,setKit] = useState({})
  const [kitList,setKitList] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemIngredientList, setItemIngredientList] = useState([]);
  const [methodList, setMethodList] = useState([]);
  const [ingredientList, setIngredientList] = useState([]);
  const [loading, setLoading] = useState(false)
  const [noRecordsFound, setNoRecordsFound] = useState(false);


  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    getMethodList()
  }, []);

  useEffect(()=>{
    getIngredientList()
  },[itemIngredientList])

  useEffect(() => {
    if(fromDate && toDate){
        getKitList();
    }
  }, [fromDate, toDate, session, item, netIssueStatus])

  useEffect(()=>{
    if(activeIndex !== null){
      getKitItemsList()
    }
  },[kit,item,activeIndex])

  const handelFilterChange = async (from_date, to_date, session, item, requirementStatus, orderStatus, orderId, purchaseStatus, receiptStatus,issueStatus, ingredient) => {
        setActiveIndex(null)
        setFromDate(from_date);
        setToDate(to_date);
        setSession(session);
        setItem(item);
        setNetIssueStatus(issueStatus)
  };

  const getIngredientList = async () => {
    const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`);
    const existingIngredientIds = itemIngredientList?.map(item => item.ingredient_id) || [];
    const list = response.data.data.filter((item) => item.status == 1 && !existingIngredientIds.includes(item.id));
    setIngredientList(list);
  };

  const getMethodList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=method_master&condition=status=1`
    );
    setMethodList(response.data.data);
  };

  const getKitItemsList = async ()=>{
    setLoading(true)
    const kit = kitList.find((item, index) => index == activeIndex);
    const condition = `ird.menu_item_id='${kit.menu_item_id}' AND ird.date = '${kit.date}' AND ird.session='${kit.session}' AND ird.batch = '${kit.batch}' AND im.store = 3`;
    const RecipeDbData = await axios.get(`${baseUrl}/issue/ingredient_list?condition=${condition}`);
    const ingredientsList = RecipeDbData.data.data;
   
    if(ingredientsList.length > 0){
      const updatedIngredientsList = [];
      for (const i of ingredientsList) {
      
          const condition = `date = '${kit.date}' AND session = '${kit.session}' AND menu_item_id = '${kit.menu_item_id}' AND batch = '${kit.batch}' AND ingredient_id = '${i.ingredient_id}'`;
          const response = await axios.get( `${baseUrl}/comman/list?table=item_requirement_detail&condition=${condition}`);
          let wastage;
          let gross_qty;
          let gross_issued_qty;
          let net_issued_qty
          let issue_net_status;
          if(response.data.data.length > 0){
            wastage = response.data.data[0].wastage ?? 0;
            gross_qty = response.data.data[0].gross_required_qty ?? 0;
            gross_issued_qty = response.data.data[0].gross_issued_qty ?? 0;
            net_issued_qty = response.data.data[0].net_issued_qty ;
            issue_net_status = response.data.data[0].issue_net_status
          }else{
            wastage = parseFloat(i.wastage ?? 0);
            gross_qty = (parseFloat(i.required_qty) + (parseFloat(i.required_qty) * (wastage / 100))).toFixed(2);
          }

          const method = methodList.find((idx) => idx.id === i?.unit);
          
          const unitname = method?.name ?? "-";
          updatedIngredientsList.push({
            ...i,
            wastage: wastage,
            unitname: unitname,
            gross_required_qty: gross_qty,
            gross_issued_qty:gross_issued_qty,
            net_issued_qty: net_issued_qty ?? 0,
            issue_net_status: issue_net_status
          });
        };
      // setSelectedItem(item);
      setItemIngredientList(updatedIngredientsList);
    }
    setLoading(false)
  }

  const getKitList = async () => {
        setLoading(true)
        setNoRecordsFound(false)
        let condition = `requirement_status = 2 AND ingredient_added = 2`;
        if(fromDate == toDate){
            condition += ` AND date = '${fromDate}' `;
        }else if(fromDate && toDate){

          condition += ` AND (date >= '${fromDate}' AND date  <= '${toDate}') `;
        }
        if(session?.value && session?.value != "undefined"){
          condition += ` AND session = '${session?.value}'`;
        }
        if(item?.value && item?.value != "undefined"){
          condition += ` AND menu_item_id = '${item?.value}'`;
        }
        if(netIssueStatus?.value && netIssueStatus != 'undefined'){
          condition += ` AND issue_net_status = '${netIssueStatus?.value}'`
        }
        const response = await axios.get(`${baseUrl}/comman/list?table=item_requirement_master&condition=${condition}`)
        const kitList = response.data.data;
        setKitList(kitList);
        setNoRecordsFound(kitList.length === 0);
        setLoading(false)
}
  const getRequirementList = async() => {
    const response = await axios.post(`${baseUrl}/issue/list`,
      { from_date : fromDate,
        to_date :toDate,
        session :session?.value,
        item : item?.value,
        issue_status: netIssueStatus?.value
       
    });
    setRequirementList(response.data.data);
  }

  
  const handleSubmit = async() => {
   
    const response = await axios.post(`${baseUrl}/requirement/status_update`,
      { selectedRows : selectedRows,
        status: 2});

      toast.success("Data successfully saved!");
      getRequirementList();
  };

  const handleToggleSelect = (index) => {
    const updatedSelectedRows = selectedRows.includes(requirementList[index])
        ? selectedRows.filter(item => item !== requirementList[index]) // Deselect
        : [...selectedRows, requirementList[index]]; // Select

   setSelectedRows(updatedSelectedRows);
    

};
const handleSwitchSelectAll = () => {
  if (selectAll) {
      setSelectedRows([]);
  } else {
      setSelectedRows(requirementList.filter((item) => item.issue_status == 1)); // Select all rows
  }
  setSelectAll(!selectAll);
};

  const clearItem = () => {
    setActiveIndex(null)
    setSelectedItem(null);
    setItemIngredientList(null);
    getKitList()
  };

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
    setKit(kitList.find((item, i) => i == index))
  };
  

  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;
    
    setItemIngredientList((prevFormData) => {
      const updatedFormData = [...prevFormData];
      // if(name === "net_issued_qty"){
      //   const required_qty = updatedFormData[index].required_qty;
      //   const diff_qty = (value - required_qty).toFixed(3);
      //   updatedFormData[index] = { ...updatedFormData[index], [name]: value, diff_qty : diff_qty };
      // }else{
        updatedFormData[index] = { ...updatedFormData[index], [name]: value };
      // }
      return updatedFormData;
    });
  };

  const handleSave = async() => {
    if(itemIngredientList.some(item => item.ingredient_id === null)){
      alert("One or more items have an empty ingredient value.");
      return
    }
    const issueCondition = itemIngredientList.some(item => 
      item.issue_status !== 3 && item.net_issued_qty > item.gross_issued_qty
    );

    if(issueCondition){
      const commonline = `ની નેટ ઇસ્યુ ક્યુએન્ટીટી ગ્રોસ ઇસ્યુ ક્યુએન્ટીટી કરતાં વધુ છે.`
      let confirmMessage = ``

      itemIngredientList.forEach(item => {
        if(item.issue_status !== 3 && item.net_issued_qty > item.gross_issued_qty){
          confirmMessage += `${item.ingredient} ${commonline}\n`
        }
      })

      confirmMessage += `શું તમે આગળ વધવા માંગો છો?`
      const confirmed = window.confirm(confirmMessage)
      if(!confirmed) return
    }
    try{
      const response = await axios.post(`${baseUrl}/issue_net/ingredient_update`,
        { ingredientsList : itemIngredientList.map(item => { const { gross_issued_qty, ...rest } = item; return rest;}),
          item: kit});
          toast.success("Item added successfully!");
          clearItem()
    }catch (error) {
      toast.error("Failed to add item. Please try again.");
    }
    
  }




  const handleNewIngredientChange = (selectedOptions,index) => {
    // setNewIngredient((prev)=>({...prev,ingredient:ingredient}))
    const updatedList =   [...itemIngredientList]
    updatedList[index].ingredient_obj = selectedOptions
    updatedList[index].ingredient = selectedOptions.ingredient
    updatedList[index].ingredient_id = selectedOptions.value
    updatedList[index].unit = selectedOptions.unit
    updatedList[index].unitname = selectedOptions.unitname
    setItemIngredientList(updatedList)
  }

  const handleExport = async(e) => {
    e.preventDefault();
    try{
      const headerRow = ["Ingredient", "Net Required Qty", "Net Issue Qty", "Gross Issue Qty",  "Gross Required Qty", "Issue Status"];
      
      const csv = [headerRow].concat(
        itemIngredientList.map(item => {
             // Replace status values with "active" or "inactive"
            const status = item.issue_net_status == 3 ? "Issued" : (item.issue_net_status == 2 ? "Partly Issue" : "Pending");
             // Create an array of values, including the modified status
             return [item.ingredient,item.required_qty,item.net_issued_qty, item.gross_issued_qty,item.gross_required_qty, status].join(',');
           })).join('\n');
     
           // Create a Blob containing the CSV data
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  
        const kitName = kitList[activeIndex].kit_no.replace(/\//g, "_")
        // Trigger the file download using file-saver
        saveAs(blob, `veg_store_net_${kitName}.csv`);
    }catch (error) {
      toast.error("Failed to export item. Please try again.");
    }
  }

  return (
    <div className="p-4">
      {loading && <Loader/>}
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
         Issue (Net)
        </h1>
        
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <VegRequirementFilter handleFilterChange={handelFilterChange} page="issue" />
      </div>
      {noRecordsFound && <NoRecordsFound/>}
      <div>
        {kitList.map((item,index) => (
          <div key={index} className="border-b mt-4">
            <div
            className="flex justify-between items-center p-4 cursor-pointer accordion-title bg-orange-400 border rounded"
            onClick={() => handleClick(index)}>
            <span>{`${item.kit_no} (${item.issue_net_status === 1 ? 'Pending issue' : (item.issue_net_status == 2 ? "Partly Issue" : "Issued")  })`}</span>
            {index === activeIndex ? (
              <ChevronUpIcon className="w-6 h-6" />
            ) : (
              <ChevronDownIcon className="w-6 h-6" />
            )}
            </div>
            {index === activeIndex && (
                <div className="space-y-2 p-4 ">
                <div className="overflow-x-auto">
                  <table className="w-full mt-10 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr> 
                        <th scope="col" className="px-2 py-1 border border-black text-center ">Ingredient</th>
                        {/* <th scope="col" className="px-2 py-1 border border-black text-center ">Unit</th> */}
                        <th scope="col" className="px-2 py-1 border border-black text-center ">Net Required Qty</th>
                        <th scope="col" className="px-2 py-1 border border-black text-center ">Net Issue Qty</th>
                        <th scope="col" className="px-2 py-1 border border-black text-center ">Gross Issue Qty</th>
                        <th scope="col" className="px-2 py-1 border border-black text-center ">Gross Required Qty</th>
                        {/* <th scope="col" className="px-2 py-1 border border-black text-center ">Diff Qty</th> */}
                        <th scope="col" className="px-2 py-1 border border-black text-center ">Issue Status</th>
                      </tr>
                    </thead>
                    <tbody>
                    {itemIngredientList?.map((item, index) =>(
                                <tr key={index} className="border border-black-b">
                                  {item.isNew ? (<td className="px-2 py-1 border border-black text-center"><Select
                                      // isMulti
                                      classNamePrefix="select"
                                      className='basic-multi-select'
                                      value={item.ingredient_obj}
                                      name="ingredient"
                                      onChange={(selectedOptions) => handleNewIngredientChange(selectedOptions,index)}
                                      options={ingredientList.map((item) => ({
                                          value: item.id,
                                          label: `${item.ingredient} (${item.eng_name})`,
                                          unit:item.unit?.id,
                                          unitname:item.unit?.name,
                                          ingredient: `${item.ingredient}`,
                                          eng_name: item.eng_name,
                                        }))}
                                      placeholder="Ingredient List"
                                      filterOption={(option, inputValue) => {
                                          return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                                      }}
                                  /></td>) :  <td className="px-2 py-1 border border-black text-center">{item.ingredient}</td>}
                                  
                                  {/* <td className="px-2 py-1 border border-black text-center">{item.unitname}</td> */}
                                  <td className="px-2 py-1 border border-black text-center">{item?.required_qty}</td>
                                  {/* <td className="px-2 py-1 border border-black text-center"><input type="number" 
                                      step="0.01" 
                                      id="gross_issued_qty" 
                                      name="gross_issued_qty" 
                                      disabled={item.issue_status === 2}
                                      value={item?.gross_issued_qty} 
                                      onChange={handleInputChange(index)}
                                      className="px-2 py-1 border rounded-md w-20 bg-orange-50"
                                    /></td> */}
                                  <td className="px-2 py-1 border border-black text-center"><input type="number" 
                                      id="net_issued_qty" 
                                      name="net_issued_qty" 
                                      disabled={item.issue_net_status === 3}
                                      value={item?.net_issued_qty} 
                                      onChange={handleInputChange(index)}
                                      onWheel={(e) => e.target.blur()}
                                      onKeyDown={(e) => {
                                        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                          e.preventDefault(); // Disable up and down arrow keys
                                        }
                                      }} 
                                      className="px-2 py-1 border rounded-md w-20 bg-orange-50"
                                    /></td>
                                    <td className="px-2 py-1 border border-black text-center">{item?.gross_issued_qty}</td>

                                  <td className="px-2 py-1 border border-black text-center">{item?.gross_required_qty}</td>
                                  {/* <td className={`px-2 py-1 border border-black text-center ${item.diff_qty < 0 ? 'bg-red-500 text-black' : ''}`}>{item?.diff_qty}</td> */}
                                  <td className="px-2 py-1 border border-black text-center">{item.issue_net_status == 3 ? "Issued" : (item.issue_net_status == 2 ? "Partly Issue" : "Pending")}</td>
                                </tr>
                              ))}
                    </tbody>
                  </table>
                </div>
                
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                          {kit.issue_net_status !==3 &&(<>
                          
                            <button
                              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              disabled={kit.issue_net_status == 3}
                              onClick={() => handleSave()}
                            >
                              Submit for Issue
                            </button>
                            {/* <button
                              className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              type="button"
                              disabled={kit.issue_net_status === 3}
                              onClick={() =>{handleAddNewIngredientRow()}}
                            >
                              Add Ingredient
                            </button> */}
                            </>)}
                            <button 
                              onClick={handleExport}
                              className="px-4 py-2 bg-green-500 text-white rounded-md mr-4 hover:bg-rose-600 focus:outline-none focus:ring focus:ring-rose-200">
                                Export
                            </button>
                  </div>
                </div>)}
          </div>
        ))}
      </div>

      
    </div>
  );
}

export default IssueNetList;
