// src/components/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import {baseUrl} from "../../comman/config";
import { useNavigate, useLocation } from 'react-router-dom';

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { from } = location.state || { from: { pathname: "/" } };

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ username: '', password: '' });

    const handleLogin = async () => {
        // Clear previous errors
        setErrors({ username: '', password: '' });

        let hasError = false;
        const newErrors = { username: '', password: '' };

        if (!username) {
            newErrors.username = 'Username is required';
            hasError = true;
        }

        if (!password) {
            newErrors.password = 'Password is required';
            hasError = true;
        }

        if (hasError) {
            setErrors(newErrors);
            return;
        }

        // Implement your login API call here
        try {
            
            const response = await axios.post(`${baseUrl}/auth/login`, {
                username: username,
                password:password
            });
            if (response.data.status) {

                localStorage.setItem('userRole', response.data.user.role);
                localStorage.setItem('username', response.data.user.username);
                navigate(from);
            } else {
                // Handle API errors
                // const errorData = await response.json();
                setErrors({ ...newErrors, general: response.data.msg });
            }
        } catch (error) {
            console.log(error);
            setErrors({ ...newErrors, general: 'An error occurred. Please try again later.' });
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="max-w-md w-full bg-white rounded-lg p-8 shadow-lg">
                <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
                {errors.general && <p className="text-red-500 text-center mb-4">{errors.general}</p>}
                <form>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                            Username
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="username"
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        {errors.username && <p className="text-red-500 text-xs italic">{errors.username}</p>}
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Password
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="password"
                            type="password"
                            placeholder="********"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {errors.password && <p className="text-red-500 text-xs italic">{errors.password}</p>}
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            type="button"
                            onClick={handleLogin}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Sign In
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
