import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../comman/config";
import Select from "react-select";
import { useNavigate, Link } from "react-router-dom";

export default function Form({ detail, handelIngredientList, duplicate }) {
  const [formData, setFormData] = useState({
    menuitem_id: "",
    maharaj_id:""
  });
  const [validationErrors, setValidationErrors] = useState({
    menuitem_id: false,
    maharaj_id:false
  });
  const [menuitemList, setMenuitemList] = useState([]);
  const [maharajList, setMaharajList] = useState([])
  const [ingredientList, setIngredientList] = useState([]);
  const [menuitemCategory, setMenuitemCategory] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [rows, setRows] = useState([
    { id: 1, ingredients_id: "", qty: "", ingredients_unit: "" },
  ]);
  const [processRows, setProcessRows] = useState([
    { id: 1, process: "" },
  ]);
  const [rowCount, setRowCount] = useState(1);
  const [processRowCount, setProcessRowCount] = useState(1);
  const [popupOpen, setPopupOpen] = useState(false);
  const formRef = useRef(null);

  const navigate = useNavigate();
  useEffect(() => {
    getMenuitemList();
    getMaharajList();
    getIngredientList();
    getMenuitemCategory();
    // getIngredientCategory()
    getUnitList();
  }, []);

  useEffect(() => {
    const callApi = async () => {
    if (detail && ingredientList) {
      console.log('detail',detail)
      
      setFormData({
        menuitem_id: detail[0].id || "",
        maharaj_id : detail[0].maharaj?.id || '',
      });
      const condition = `menu_item_id = ${detail[0].id} AND maharaj = ${detail[0].maharaj?.id}`;
      const ingResponse = await axios.get(
        `${baseUrl}/comman/list?table=receipe_master&condition=${condition}`
      );
      const newArray = ingResponse.data.data
      .map((item, index) => {
        const unit =
          ingredientList.find((v) => v.id == item.ingredient_id)?.unit ?? null;
        return {
          id: index + 1,
          ingredients_id: item.ingredient_id.toString(),
          qty: item.qty,
          ingredients_unit: unit,
        };
      });
      
      const response = await axios.get(
        `${baseUrl}/comman/list?table=receipe_master_process&condition=${condition}`
      );
      // console.log(response.data);
      setRows(newArray);
      setRowCount(newArray.length);
      setProcessRows(response.data.data);
      setProcessRowCount(response.data.data.length);
    }
  }
  callApi();
    // console.log(detail[0]);
  }, [detail, ingredientList]);

  const getMenuitemList = async () => {
    const response = await axios.get(`${baseUrl}/item/list`);
    const list = response.data.data.filter((item) => item.status == 1);
    setMenuitemList(list);
  };

  const getMaharajList = async () => {
    const response = await axios.get(`${baseUrl}/maharaj/list`);
    const filteredList = response.data.data.filter((maharaj)=> maharaj.status === 1)
    setMaharajList(filteredList);
} 
  const getIngredientList = async () => {
    const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`);
    const list = response.data.data.filter((item) => item.status == 1);
    setIngredientList(list);
    if(detail){
      handelIngredientList(list);
    }
    
  };
  const getMenuitemCategory = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=item_category_master&condition=status=1`
    );
    setMenuitemCategory(response.data.data);
  };

  const getUnitList = async () => {
    const response = await axios.get(`${baseUrl}/comman/list?table=units`);
    setUnitList(response.data.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const gujaratiRegex = /^[\u0A80-\u0AFF\s]+$/;
    const gujaratiRegex = /^[\u0A80-\u0AFF\s()+\-]*$/;
    const newValidationErrors = {
      menuitem_id: formData.menuitem_id === "",
      maharaj_id: formData.maharaj_id === "",
      rows: isAddRowDisabled(),
    };
    console.log(rows);
    setValidationErrors(newValidationErrors);
    const hasErrors = Object.values(newValidationErrors).some((error) => error);
    // console.log(formData);
    if (hasErrors) {
      // Find the first invalid input element
      const firstInvalidInput = formRef.current.querySelector(".invalid");
      console.log(firstInvalidInput);
      if (firstInvalidInput) {
        // Scroll to the first invalid input element
        firstInvalidInput.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    } else {
      let url;

      try {
        setPopupOpen(true);
        if (detail && !duplicate) {
          url = `receipe/edit/${detail[0].id}`;
        } else {
          url = "receipe/add";
        }
        const response = await axios.post(`${baseUrl}/${url}`, {
          formData: formData,
          rows: rows,
          processRows : processRows
        });
        setPopupOpen(false);
        if (response.data.status == true) {
          if (detail && !duplicate) {
            window.location.reload();
          }else{
            navigate("/receipe/list");
          }
        } else {
          setValidationErrors({ error: response.data.msg });
        }
        //
      } catch (error) {
        setPopupOpen(false);
        console.log(error);
      }
    }
  };

  const handleMenuitemChange = (value) => {
    setFormData({ menuitem_id: value });
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,

        }));
  
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false
    }));
};
  const handleAddRow = (e) => {
    e.preventDefault();
    // console.log(rowCount);
    setRowCount(rowCount + 1);
    setRows([
      ...rows,
      { id: rowCount + 1, ingredients_id: "", qty: "", ingredients_unit: "" },
    ]);
  };

  const handleProcessAddRow = (e) => {
    e.preventDefault();
    setProcessRowCount(processRowCount + 1);
    setProcessRows([
      ...processRows,
      { id: processRowCount + 1, process: "" },
    ]);
  }

  const handleRemoveRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleProcessRemoveRow = (id) => {
    setProcessRows(processRows.filter((row) => row.id !== id));
  };

  const handleIngredientsChange = (id, value) => {
    const unit = ingredientList.find((item) => item.id == value).unit;
    const selectedIngredient = rows.find((item) => item.ingredients_id == value);
    console.log(selectedIngredient);
    if (selectedIngredient) {
      // Display an alert if the value does not exist
      alert("Selected ingredient allready exist!");
      return;
  }
    setRows(
      rows.map((row) =>
        row.id === id
          ? { ...row, ingredients_id: value.toString(), ingredients_unit: unit }
          : row
      )
    );
  };

  const handleQtyChange = (id, value) => {
    setRows(rows.map((row) => (row.id === id ? { ...row, qty: value } : row)));
  };
  const handleProcessChange = (id, value) => {
    setProcessRows(processRows.map((row) => (row.id === id ? { ...row, process: value } : row)));
  }

  const handleIngredientsUnitChange = (id, value) => {
    setRows(
      rows.map((row) =>
        row.id === id ? { ...row, ingredients_unit: value } : row
      )
    );
  };

  const isRowValid = (row) => {
    // console.log(row);
    if (row.ingredients_id.trim() !== "" && row.qty.trim() !== "") {
      // Check if qty is a valid integer
      const qtyRegex = /^[0-9]+(?:\.[0-9]+)?$/;
      return qtyRegex.test(row.qty.trim());
    }
    return false;
  };
  const isAddRowDisabled = () => {
    return rows.some((row) => !isRowValid(row));
  };


  // Function to handle scrolling
  const scrollToProcess = (e) => {
    e.preventDefault();
    const element = document.querySelector('.production_process');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
    <form ref={formRef}>
      <div className="space-y-12">
        <div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-6 gap-x-5 gap-y-8">
            <div className="md:col-span-2 ms-4">
              <label
                htmlFor="menuitem_id"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Item Name
              </label>
              <div className="mt-2">
                <Select
                  classNamePrefix="select"
                  value={
                    menuitemList
                      .filter((item) => item.id === formData.menuitem_id)
                      .map((item) => ({
                        value: item.id,
                        label: `${item.item_name} (${item.eng_name})`,
                        eng_name: item.eng_name,
                      }))[0]
                  }
                  name="menuitem_id"
                  onChange={(selectedOption) => {
                    handleMenuitemChange(selectedOption.value);
                  }}
                  options={menuitemList.map((item) => ({
                    value: item.id,
                    label: `${item.item_name} (${item.eng_name})`,
                    eng_name: item.eng_name,
                    category: item.category,
                  }))}
                  isDisabled={detail && detail.length > 0 && !duplicate}
                  filterOption={(option, inputValue) => {
                    return (
                      option.data.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase()) ||
                      option.data.eng_name
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    );
                  }}
                />
              </div>
              {validationErrors.menuitem_id && (
                <div className="text-red-500">Please select a menuitem.</div>
              )}
            </div>
            
          </div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-x-5 gap-y-8">
              <div className="md:col-span-2 ms-4">
                  <label htmlFor="maharaj_id" className="block text-sm font-medium leading-6 text-gray-900">Maharaj Name</label>
                  <div className="mt-2">
                      <select 
                          id="maharaj_id"
                          name="maharaj_id"
                          type="text"
                          value={formData.maharaj_id}
                          onChange={handleInputChange}
                          disabled={detail && detail.length > 0 && !duplicate}
                          className={`block w-full rounded-md border ${validationErrors.maharaj_id ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}>
                              <option value="">Select Maharaj</option>
                              {maharajList.map((item) => (
                                  <option value={item.id} key={item.id}>{item.maharaj_name}</option>
                              ))};
                          </select>
                      
                      </div>
                  {validationErrors.maharaj_id && <div className="text-red-500">Please enter a maharaj name.</div>}
              </div>

            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
        <Link to="/receipe/list">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
        </Link>
          <button onClick={scrollToProcess} className="px-4 py-2 bg-blue-500 text-white rounded-md placeholder:text-sm mr-2">Production Process</button>
        <button
          onClick={handleSubmit}
          type="submit"
          className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
          <div className="mt-5">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr className="text-center">
                  <th className="px-4 py-2">Ingredients</th>
                  <th className="px-4 py-2">Qty / 100 kg</th>
                  <th className="px-4 py-2">Unit</th>
                  <th className="px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {rows.map((row) => (
                  <tr key={row.id}>
                    <td className="px-4 py-2 text-center">
                      <Select
                        classNamePrefix="select"
                        className="w-80 "
                        value={
                          ingredientList
                            .filter((item) => item.id == row.ingredients_id)
                            .map((item) => ({
                              value: item.id,
                              label: `${item.ingredient} (${item.eng_name})`,
                              eng_name: item.eng_name,
                            }))[0]
                        }
                        onChange={(selectedOption) => {
                          handleIngredientsChange(row.id, selectedOption.value);
                        }}
                        options={ingredientList.map((item) => ({
                          value: item.id,
                          label: `${item.ingredient} (${item.eng_name})`,
                          eng_name: item.eng_name,
                        }))}
                        filterOption={(option, inputValue) => {
                          return (
                            option.data.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase()) ||
                            option.data.eng_name
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          );
                        }}
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <input
                        type="number"
                        value={row.qty}
                        onChange={(e) =>
                          handleQtyChange(row.id, e.target.value)
                        }
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                        className="w-20  rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      <input
                        type="text"
                        value={row.ingredients_unit?.name}
                        disabled
                        className="w-20 rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      
                      {row.id !== 1 && (
                        <button
                          onClick={() => handleRemoveRow(row.id)}
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        >
                          Remove
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {validationErrors.rows && (
              <div className="text-red-500">
                Please at least one ingredient and their quantity.
              </div>
            )}
          </div>
        </div>
        {validationErrors.error && (
          <div className="text-red-500">
            Please check receipe already exist for this menu item.
          </div>
        )}
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
      
                       
       
        <button
                          onClick={handleAddRow}
                          disabled={isAddRowDisabled()}
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 text-sm rounded"
                        >
                          Add Row
                        </button>
        
      </div>
    </form>
    <div className="production_process">
      
      <h3 className="text-lg sm:text-l md:text-l lg:text-l  font-bold">Standard Porduction Process</h3>
      <div className="mt-5">
            <table className="divide-y divide-gray-200">
              
              <tbody className="bg-white divide-y divide-gray-200">
                {processRows.map((row, index) => (
                  <tr key={row.id}>
                    <td className="px-4 py-2 text-center">Step {index + 1}</td>
                    <td className="px-4 py-2 text-center">
                      <input
                        type="text"
                        value={row.process}
                        onChange={(e) => handleProcessChange(row.id, e.target.value)}
                        placeholder="Process Step"
                        className="w-full rounded-md border border-gray-300 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                      />
                    </td>
                    <td className="px-4 py-2 text-center">
                      
                      {row.id !== 1 && (
                        <button
                          onClick={() => handleProcessRemoveRow(row.id)}
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        >
                          Remove
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
      
                       
       
        <button
                          onClick={handleProcessAddRow}
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 text-sm rounded"
                        >
                          Add Process Row
                        </button>
        
      </div>
    </div>
    </>
  );
}
