import React, { useState, useEffect } from "react";
import axios from "axios";
import {baseUrl} from "../comman/config";
import Select from "react-select";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { DateSessionFilter } from "../components/Filter/DateSessionFilter";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from 'xlsx';
import ModalContent from "../components/ModalContent";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";


function ReceipePlanning() {
  const today = new Date().toISOString().split("T")[0];
  const [mealList, setMealList] = useState([]);
  const [rmDetailForItem, setRMDetailForItem] = useState([]);
  const [maharajName, setMaharajName] = useState('');
  const [maharajDetail, setMaharajDetail] = useState('');
  const [itemDisable, setItemDisable] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [ingredientList, setIngredientList] = useState([]);
  const [date, setDate] = useState(today);
  const [session, setSession] = useState("");
  const [formData, setFormData] = useState([]);
  const [processStep, setProcessStep] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const role = localStorage.getItem('userRole');

  const navigate = useNavigate();

  useEffect(() => {
    getIngredientList();
  }, []);

  useEffect(() => {
    if (mealList.length > 0 && activeIndex != null) {
      getRMDetailForItem();
    }
  }, [mealList, activeIndex]);

  useEffect(() => {
    if (mealList.length > 0 && activeIndex != null) {
      console.log(activeIndex);
      console.log(mealList);
      const itemId =
        mealList.find((item, index) => index === activeIndex)?.menu_item_id ??
        null;
      const maharajId = mealList.find((item, index) => index === activeIndex)?.maharaj ?? null;
      const fetchDetail = async () => {
        setLoading(true)
        const condition = `menu_item_id='${itemId}' AND date = '${date}' AND session='${session.value}'`;
       
        if(maharajId){
          const maharajResponse = await axios.get(`${baseUrl}/comman/list?table=maharaj_master&condition=id='${maharajId}'`);
          console.log(maharajResponse.data.data)
          setMaharajName(maharajResponse.data.data[0]?.maharaj_name);
          setMaharajDetail(maharajResponse.data.data[0]);
        }
        const receipeMasteCondition = `menu_item_id='${itemId}' AND maharaj='${maharajId}'`;
        const processData =await axios.get(`${baseUrl}/comman/list?table=receipe_master_process&condition=${receipeMasteCondition}`);
        const RecipeData = await axios.get(`${baseUrl}/comman/list?table=receipe_master&condition=${receipeMasteCondition}`);
        const RecipeDbData = await axios.get(`${baseUrl}/comman/list?table=receipe_planning&condition=${condition} GROUP BY ingredient_id order by id asc`);
        let ddArray = [];
        if(RecipeDbData.data.data.length > 0 && ingredientList.length > 0){
          RecipeDbData.data.data.filter((item) => item && item.ingredient_id !== 9090 && item.ingredient_id !== 9091 && item.ingredient_id !== 9092 && item.ingredient_id !== 9093).forEach((item, index) => {
            const data = ingredientList.find((v) => v.id == item.ingredient_id);
            const stdQty = RecipeData.data?.data[index]?.qty ?? 0;
            console.log(data);
            const dd = {ingredient_id : data.id, name : data.ingredient, unit: data.unit, stdQty: stdQty, required_qty : item.required_qty, baseQty: 0, store : data.store, db_id: item.id}
            
            ddArray.push(dd);
            
          });

          if(RecipeData.data.data.length > 0){
            RecipeData.data.data.forEach((item) => {
              const isPresent = ddArray.some((ddItem) => ddItem.ingredient_id === item.ingredient_id)

              if(!isPresent){
                const data = ingredientList.find((v) => v.id == item.ingredient_id);
                const stdQty = parseFloat(item.qty);
                const dd = {ingredient_id : data.id, name : data.ingredient, unit: data.unit, stdQty: stdQty, required_qty : stdQty, baseQty: stdQty, store : data.store}
                ddArray.push(dd);
              }
              
            })
          }

          const receipe_remarks = {ingredient_id : 9090, name : "receipe_remarks", unit: "", stdQty: "", required_qty : "", baseQty: 0, store : "", remarks: ""};
          const process_remarks = {ingredient_id : 9091, name : "process_remarks", unit: "", stdQty: "", required_qty : "", baseQty: 0, store : "", remarks: ""};
          const test_remarks = {ingredient_id : 9092, name : "test_remarks", unit: "", stdQty: "", required_qty : "", baseQty: 0, store : "", remarks: ""};
          const planning_remarks = {ingredient_id : 9093, name : "planning_remarks", unit: "", stdQty: "", required_qty : "", baseQty: 0, store : "", remarks: ""};
          const checkRemarks = RecipeDbData.data.data.filter((item) => item && item.ingredient_id == 9090);
          if(checkRemarks.length > 0){
            receipe_remarks.db_id = checkRemarks[0].id;
            receipe_remarks.remarks = checkRemarks[0].remarks;
          }
          const processRemarks = RecipeDbData.data.data.filter((item) => item && item.ingredient_id == 9091);
          if(processRemarks.length > 0){
            process_remarks.db_id = processRemarks[0].id;
            process_remarks.remarks = processRemarks[0].remarks;
          }
          const testRemarks = RecipeDbData.data.data.filter((item) => item && item.ingredient_id == 9092);
          if(testRemarks.length > 0){
            test_remarks.db_id = testRemarks[0].id;
            test_remarks.remarks = testRemarks[0].remarks;
          }
          const planningRemarks = RecipeDbData.data.data.filter((item) => item && item.ingredient_id == 9093);
          if(planningRemarks.length > 0){
            planning_remarks.db_id = planningRemarks[0].id;
            planning_remarks.remarks = planningRemarks[0].remarks;
          }
          ddArray.push(test_remarks); 
          ddArray.push(process_remarks);
          ddArray.push(receipe_remarks);
          ddArray.push(planning_remarks);
          
        }else if(RecipeData.data.data.length > 0 && ingredientList.length > 0){
          RecipeData.data.data.forEach((item) => {
            const data = ingredientList.find((v) => v.id == item.ingredient_id);
            
            const stdQty = parseFloat(item.qty);
            const dd = {ingredient_id : data.id, name : data.ingredient, unit: data.unit, stdQty: stdQty, required_qty : stdQty, baseQty: stdQty, store : data.store}
            ddArray.push(dd);
          })

          const receipe_remarks = {ingredient_id : 9090, name : "receipe_remarks", unit: "", stdQty: "", required_qty : "", baseQty: 0, store : "", remarks: ""};
          const process_remarks = {ingredient_id : 9091, name : "process_remarks", unit: "", stdQty: "", required_qty : "", baseQty: 0, store : "", remarks: ""};
          const test_remarks = {ingredient_id : 9092, name : "test_remarks", unit: "", stdQty: "", required_qty : "", baseQty: 0, store : "", remarks: ""};
          const planning_remarks = {ingredient_id : 9093, name : "planning_remarks", unit: "", stdQty: "", required_qty : "", baseQty: 0, store : "", remarks: ""};
          ddArray.push(test_remarks); 
          ddArray.push(process_remarks);
          ddArray.push(receipe_remarks);
          ddArray.push(planning_remarks);
        }else{
          ddArray = [];
          const receipe_remarks = {ingredient_id : 9090, name : "receipe_remarks", unit: "", stdQty: "", required_qty : "", baseQty: 0, store : "", remarks: ""};
          const process_remarks = {ingredient_id : 9091, name : "process_remarks", unit: "", stdQty: "", required_qty : "", baseQty: 0, store : "", remarks: ""};
          const test_remarks = {ingredient_id : 9092, name : "test_remarks", unit: "", stdQty: "", required_qty : "", baseQty: 0, store : "", remarks: ""};
          const planning_remarks = {ingredient_id : 9093, name : "planning_remarks", unit: "", stdQty: "", required_qty : "", baseQty: 0, store : "", remarks: ""};
          ddArray.push(test_remarks); 
          ddArray.push(process_remarks);
          ddArray.push(receipe_remarks);
          ddArray.push(planning_remarks);
        }
        setProcessStep(processData.data.data);
        setFormData(ddArray);
        setLoading(false)
      };
      fetchDetail();
    }
  }, [mealList, activeIndex, rmDetailForItem, ingredientList]);

  useEffect(() => {
    const savedIndex = localStorage.getItem('activeIndex');
    if (savedIndex !== null) {
      setActiveIndex(Number(savedIndex)); // Assuming you have a state setter for activeindex
    }
  
    // Optionally, clear the saved index if no longer needed
    localStorage.removeItem('activeIndex');
  }, []);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
    localStorage.setItem('activeIndex', index === activeIndex ? null : index);

  };

  const getIngredientList = async () => {
    const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`);
    const list = response.data.data.filter((item) => item.status == 1);
    setIngredientList(list);
  };

  const getRMDetailForItem = async () => {
    setLoading(true)
    const item_id = mealList.find((item, index) => index == activeIndex);
    const condition = `date = '${date}' AND session = '${session.value}' AND menu_item_id = '${item_id.menu_item_id}'`;
    const response = await axios.get(
      `${baseUrl}/comman/list?table=raw_material&condition=${condition}`
    );
    const frezzCondition = `menu_item_id='${item_id.menu_item_id}' AND date = '${date}' AND session='${session.value}'`;
    const checkFrezz = await axios.get(`${baseUrl}/comman/list?table=production_receipe_planning&condition=${frezzCondition}`);
    const freez = checkFrezz.data.data.length > 0 ? true : false;
    setItemDisable(role == 1 ? false : freez);
    setRMDetailForItem(response.data.data);
    setLoading(false)
  };

  const handelFilterChange = async (date, session) => {
    setDate(date);
    setSession(session);
    if (date && session) {
      const condition = ` m.session='${session.value}' AND m.date='${date}'`;
      const response = await axios.get(
        `${baseUrl}/meal_master/item_list?condition=${condition}`
      );
      setMealList(response.data.data);
    }
  };


    const handlePlannedQtyChange = (e, index) => {
        const { name, value } = e.target;

        if(name == 'receipe_remarks'){
          setFormData((prevFormData) => {
            const updatedFormData = prevFormData.map(item => 
              // Check if the item has the specified ingrid_id
              
              item.ingredient_id == 9090
                  ? { ...item, remarks: value, name: name} // Update the item
                  : item // Leave other items unchanged
          );
          return updatedFormData;
        });
        }else if(name == 'process_remarks'){
          setFormData((prevFormData) => {
            const updatedFormData = prevFormData.map(item => 
              // Check if the item has the specified ingrid_id
              
              item.ingredient_id == 9091
                  ? { ...item, remarks: value, name: name} // Update the item
                  : item // Leave other items unchanged
          );
          return updatedFormData;
        });
        }else if(name == 'test_remarks'){
          setFormData((prevFormData) => {
            const updatedFormData = prevFormData.map(item => 
              // Check if the item has the specified ingrid_id
              
              item.ingredient_id == 9092
                  ? { ...item, remarks: value, name: name} // Update the item
                  : item // Leave other items unchanged
          );
          return updatedFormData;
        });
        }else if(name == 'planning_remarks'){
          setFormData((prevFormData) => {
            const updatedFormData = prevFormData.map(item => 
              // Check if the item has the specified ingrid_id
              
              item.ingredient_id == 9093
                  ? { ...item, remarks: value, name: name} // Update the item
                  : item // Leave other items unchanged
          );
          return updatedFormData;
        });
        }else{
          setFormData((prevFormData) => {
            const updatedFormData = [...prevFormData];
            updatedFormData[index] = { ...updatedFormData[index], [name]: value };
            return updatedFormData;
        });
        }
        
    };

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true)
    const menu_item_id =
      mealList.find((item, i) => i == activeIndex)?.menu_item_id ?? 0;
    const combineData = {
      formData: formData.filter((item) => item && item.name !== "receipe_remarks" && item.name !== "process_remarks" && item.name !== "test_remarks" && item.name !== "planning_remarks"),
      date: date,
      session: session,
      menu_item_id: menu_item_id,
    };
    console.log(combineData);
    try {
      // setPopupOpen(true);

      await axios.post(`${baseUrl}/production_planning/receipe`, combineData);
      if(formData.filter((item) => item && item.name == "planning_remarks")){
        handleSaveFeedback(e);
      }
      toast.success("Data successfully saved!");
      window.location.reload();
    } catch (error) {
      toast.error("Failed to submit the form.");
      // setPopupOpen(false);
      console.log(error);
    }
    setLoading(false)
  };

  const handleSaveFeedback = async (e) => {
    e.preventDefault();
    const menu_item_id =
      mealList.find((item, i) => i == activeIndex)?.menu_item_id ?? 0;
    const combineData = {
      formData: formData.filter((item) => item && item.name == "receipe_remarks" || item.name == "process_remarks" || item.name == "test_remarks" || item.name == "planning_remarks"),
      date: date,
      session: session,
      menu_item_id: menu_item_id,
    };
    console.log(combineData);
    try {
      // setPopupOpen(true);

      await axios.post(`${baseUrl}/production_planning/remarks`, combineData);
      toast.success("Data successfully saved!");
      
      window.location.reload();
    } catch (error) {
      toast.error("Failed to submit the form.");
      // setPopupOpen(false);
      console.log(error);
    }
  }

  const handleRecipeExport = async(e,index) => {
    const menu_item_id =
      mealList.find((item, i) => i == activeIndex)?.menu_item_id ?? 0;
    const response = await axios.post(`${baseUrl}/receipe/export`, {menu_item_id : menu_item_id});

    const data = response.data.data;

    const menu_item = mealList.find((item, i) => i === activeIndex);
    const itemName = menu_item?.item_name;
    const engName = menu_item?.eng_name;
    const itemDisplayName = itemName || engName ? `${itemName ?? ''}${engName ? ` (${engName})` : ''}`.trim() : '-';
    // Separate the data by maharaj_name
    const separatedData = data.reduce((acc, curr) => {
        const { maharaj_name } = curr;
    
        if (!acc[maharaj_name]) {
            acc[maharaj_name] = [];
        }
        acc[maharaj_name].push(curr);
        return acc;
    }, {});

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Header row for the ingredients data
    const header = ["Id", "Ingredients", "Qty/100kg", "Unit"];

    // Item name array
    const itemNameArray = ['Item Name:', itemDisplayName];

    // Add separate sheets for each maharaj_name
    for (const [maharaj, ingredients] of Object.entries(separatedData)) {
        // Convert the ingredients to a 2D array format (rows and columns) for the sheet
        const maharajArray = ingredients.map((ingredient, index) => [
            index+1,
            `${ingredient?.ingredient || ingredient?.eng_name ? `${ingredient?.ingredient ?? ''}${ingredient?.eng_name ? ` (${ingredient.eng_name})` : ''}`.trim() : '-'}`,
            ingredient.qty,
            ingredient.unit
        ]);
    
        // Create a final array combining item name row, maharaj data, and header
        const finalData = [
            itemNameArray,    // Item name row at the top
            [],               // Empty row for spacing
            header,
            ...maharajArray,
        ];
    
        // Convert finalData array to sheet format
        const ws = XLSX.utils.aoa_to_sheet(finalData);
        XLSX.utils.book_append_sheet(wb, ws, maharaj);
    }

    // Write the Excel file
    const fileName = `Recipe_Planning_${date}_${session.label}_${menu_item.item_name}.xlsx`;
    XLSX.writeFile(wb, fileName);
   
}

  const handleExport = async (e, index) => {
    e.preventDefault();

    const menu_item = mealList.find((item, i) => i === activeIndex);
    const wb = XLSX.utils.book_new();

    // Group ingredients by store
    const ingredientsByStore = {};
    // console.log(formData)
    formData.forEach(item => {
        const storeName = item.store?.name || 'Main Store';
        if (!ingredientsByStore[storeName]) {
            ingredientsByStore[storeName] = [];
        }
        ingredientsByStore[storeName].push(item);
    });

    // Create sheets for each store
    Object.entries(ingredientsByStore).forEach(([storeName, items]) => {
        const ws = XLSX.utils.aoa_to_sheet([
            [""], // Placeholder for merged cell
            [],
            ["Requisition No."],
            ["Date :", date, "Session :", session.label, "Item :", menu_item.item_name],
            ["Batch :", formData[index].batch, "Vessel :", formData[index].vessel_id?.label, "Plan Batch Qty :", formData[index].planned_batch_raw_qty],
            [],
            [],
            ["Store:", storeName], // Add store name
            ["Sr. No.", "Ingredients", "Unit", "Required Qty", "Issued Qty.", "Excess Qty (support)", "Consumed Qty.", "Return to Stores (Qty.)"],
            ...items.map((item, idx) => [
                idx + 1,
                item.name,
                item.unit?.name,
                item.required_qty,
                "",
                "",
                "",
                ""
            ])
        ]);

        // Merge cells as needed
        ws['!merges'] = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
            { s: { r: 2, c: 1 }, e: { r: 2, c: 7 } },
            { s: { r: 3, c: 4 }, e: { r: 3, c: 5 } },
            { s: { r: 4, c: 4 }, e: { r: 4, c: 5 } },
            { s: { r: 3, c: 6 }, e: { r: 3, c: 7 } },
            { s: { r: 4, c: 6 }, e: { r: 4, c: 7 } },
            { s: { r: 6, c: 0 }, e: { r: 6, c: 7 } } // Merge store name cell
        ];

        // Set cell values and styles
        ws['A1'] = {
            v: "Raw Material Requisition & Issue Slip( Store / Support )",
            s: {
                alignment: { horizontal: "center" },
                font: { sz: 20, color: { rgb: "000000" } },
                fill: { patternType: "solid", fgColor: { rgb: "D8D8D8" } }
            }
        };

        ws['A3'] = { v: "Requisition No." };
        ws['B3'] = {
            v: formData[index].requisition_no,
            s: {
                alignment: { horizontal: "center" },
                font: { sz: 12, color: { rgb: "000000" } }
            }
        };

        ws['G4'] = { v: menu_item.item_name };
        ws['G5'] = { v: formData[index].planned_batch_raw_qty };

        const wscols = [
            { wch: 10 }, // Reduced width for Date and Batch columns
            { wch: 10 }, // Requisition No. column
            { wch: 10 }, // Date column
            { wch: 10 }, // Session column
            { wch: 10 }, // Item column
            { wch: 10 }, // Batch column
            { wch: 10 }, // Vessel column
            { wch: 10 }, // Plan Batch Qty column
            { wch: 5 },  // Ingredient ID column
            { wch: 10 }, // Name column
            { wch: 5 },  // Unit column
            { wch: 10 }, // Std Qty column
            { wch: 10 }, // Planned Qty column
        ];
        ws['!cols'] = wscols;

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, `Store_${storeName}`);
    });

    // Generate XLSX file and trigger download
    const finleName =  `Recipe_Planning_${date}_${session.label}_${menu_item.item_name}.xlsx`;
    console.log(finleName);
    XLSX.writeFile(wb, finleName);
};

const handleAddIngredient = async(ingredient) => {

    const ob = {
      ingredient_id : ingredient.value,
      name: ingredient.name,
      unit:ingredient.data.unit,
      stdQty: 0,
      required_qty: 0,
      baseQty:0,
      store: ingredient.data.store
    };
    setFormData((prevFormData) => [
        ...prevFormData,
        ob
    ]);
} 

const handleProcessedit = (e) => {
e.preventDefault();
const menu_item_id =
      mealList.find((item, i) => i == activeIndex)?.menu_item_id ?? 0;
      const obj = [{
        id : menu_item_id,
        maharaj : maharajDetail
      }];
 
navigate(`/receipe/edit/${btoa(menu_item_id)}`, { state: obj });
}


  return (
    <div className="">
      {loading && <Loader/>}
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
          Recipe Planning
        </h1>
        <Toaster position="top-center" reverseOrder={false} />
        {/* <div>
                    <Link to="/menu/add">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md placeholder:text-sm mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Add Menuitem</button>
                    </Link>
                </div> */}
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <DateSessionFilter handleFilterChange={handelFilterChange} />
        <div>
          {mealList.map((item, index) => (
            <div key={index} className="border-b mt-4">
              <div
                className="flex justify-between items-center p-4 cursor-pointer accordion-title bg-orange-400 border rounded"
                onClick={() => handleClick(index)}
              >
                <span>{`${item.item_name} (${item.eng_name})`}</span>
                {index === activeIndex ? (
                  <ChevronUpIcon className="w-6 h-6" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6" />
                )}
              </div>
              {index === activeIndex && (
                <div className="space-y-2 p-4 bg-white">
                  <div className="flex justify-between">
                    <div className="lg:w-96">
                      
                      
                      <div className="flex flex-wrap justify-between">
                        <div className="sm:text-sm lg:text-base font-bold">
                        Estimated Count (Persons):
                        </div>
                        <div>{rmDetailForItem?.[0]?.estimated_count ?? 0}</div>
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <div className="sm:text-sm lg:text-base font-bold">
                          Planned Ras Qty/100 (Persons):
                        </div>
                        <div>{rmDetailForItem?.[0]?.ras_qty_per_100_person ?? 0}</div>
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <div className="sm:text-sm lg:text-base font-bold">
                          Planned RM Qty (kgs):
                        </div>
                        <div>{rmDetailForItem?.[0]?.planned_rm_qty ?? 0}</div>
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <div className="sm:text-sm lg:text-base font-bold">
                        Maharaj Name:
                        </div>
                        <div>{maharajName ?? '-'}</div>
                      </div>
                      <div className="flex justify-between">
                        <div className="sm:text-sm lg:text-base font-bold">
                        Planning Remarks:
                        </div>
                        <div>
                        <textarea
                            name="planning_remarks"
                            id="remarks"
                            placeholder='Remarks'
                            value={formData.find(item => item.name === 'planning_remarks')?.remarks || ''}
                            onChange={(e) => handlePlannedQtyChange(e, 9093)}
                            className="block w-full rounded-md border py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            rows="4" // Adjust the number of rows as needed
                          />
                        </div>
                      </div>
                    </div>
                    <div className="justify-end">
                      <Link  to="#"
                        onClick={() => window.open("/reports/receipe_feedback", "_blank", "noopener,noreferrer")}>
                        <button className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm mr-2 float-right">
                        Recipe Feedback Report
                        </button>
                      </Link>
                    </div>
                    <div className="justify-end">
                      <Link  to="#"
                        onClick={() => window.open("/receipe/list", "_blank", "noopener,noreferrer")}>
                        <button className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm mr-2 float-right">
                          Add New Recipe 
                        </button>
                      </Link>
                    </div>
                    <div className="justify-end">
                        <button onClick={(e) => { handleProcessedit(e)}} className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm mr-2 float-right">
                          Add Ingredient to Recipe
                        </button>
                    </div>
                    {/* {!itemDisable && <div className="justify-end">
                      <Link to="#" 
                      onClick={() => window.open("/ingredients/add", "_blank", "noopener,noreferrer")}
                      >
                        <button className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm mr-2 float-right">
                        Add Ingredient to Master
                        </button>
                      </Link>
                    </div>} */}
                  </div>
                  <ModalContent showModalplanning_remarks={showModal} setShowModal={setShowModal} ingredientList={ingredientList} handleAddIngredient={handleAddIngredient} ingredientDetail={formData} />
                  <div className="flex overflow-x-auto">
                    
                      <div className="gap-5 mb-4 mt-10 ms-2 flex-shrink-0 ">
                       
                        {formData.length > 0 && <div className="border p-2">
                            <table className="border space-x-1">
                              <thead className="space-x-1">
                                <tr>
                                  <th className="px-3">Ingredients</th>
                                  <th className="px-3">Units</th>
                                  <th className="px-3">Std Qty / 100 kg</th>
                                  <th className="px-3">Reqd Qty / 100 kg</th>
                                  <th className="px-3">Planned RM Qty.</th>
                                </tr>
                              </thead>
                              <tbody>
                              {formData.filter((item) => item && item.name !== "receipe_remarks" && item.name !== "process_remarks" && item.name !== "test_remarks" && item.name !== "planning_remarks").map((item, idx) => (
                                <tr key={idx} className="border">
                                  <td className="text-center">{item.name}</td>
                                  <td className="text-center">{item.unit?.name ?? "-"}</td>
                                  <td className="text-center">{item.stdQty}</td>
                                  <td className="text-center p-1"><input type="number" onWheel={(e) => e.target.blur()}  onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}} step="0.01" id={`required_qty_${idx}`} disabled={itemDisable} placeholder="Required Qty"  name="required_qty" value={item.required_qty} onChange={(e) => handlePlannedQtyChange(e, idx)} className={`w-24 p-1 border rounded-md placeholder:text-sm ${itemDisable ? "" : "bg-orange-50"}`}/></td>
                                  <td className="text-center">{((rmDetailForItem?.[0]?.planned_rm_qty ?? 0) * (item.required_qty ?? 0) / 100).toFixed(3)}</td>

                                </tr>
                              ))}
                              </tbody>
                            </table>
                        </div>}
                        <div className="border p-2">
                        {!itemDisable && <>
                          <button
                            disabled={itemDisable}
                            onClick={(e) => { handleSave(e)}}
                            className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm mr-2 float-right"
                          >
                            Save
                          </button>
                          {/* <button 
                        onClick={() => setShowModal(true)}
                        disabled={itemDisable}
                        className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm mr-2 float-right"
                      >
                        Add Ingredient
                      </button> */}
                      </>}
                          <button
                            onClick={(e) => {
                              handleRecipeExport(e, index);
                            }}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md placeholder:text-sm mr-2"
                          >
                            Export
                          </button>
                          
                        </div>
                      </div>
                      <div className="gap-5 px-10">
                      <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-5 gap-y-8">
                        <div className="mt-2 ">
                          <label htmlFor="field2" className="mr-2 w-full sm:w-auto md:w-52">Recipe Ingrd. Feedback</label>
                          <textarea
                            name="receipe_remarks"
                            id="remarks"
                            placeholder='FeedBack'
                            value={formData.find(item => item.name === 'receipe_remarks')?.remarks || ''}
                            onChange={(e) => handlePlannedQtyChange(e, 9090)}
                            className="block w-full rounded-md border py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            rows="4" // Adjust the number of rows as needed
                          />
                        </div>
                        <div className="mt-2">
                          <label htmlFor="field2" className="mr-2 w-full sm:w-auto md:w-52">Production Process Feedback</label>
                          <textarea
                            name="process_remarks"
                            id="remarks"
                            placeholder='FeedBack'
                            value={formData.find(item => item.name === 'process_remarks')?.remarks || ''}
                            onChange={(e) => handlePlannedQtyChange(e, 9091)}
                            className="block w-full rounded-md border py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            rows="4" // Adjust the number of rows as needed
                          />
                        </div>
                        <div className="mt-2">
                          <label htmlFor="field2" className="mr-2 w-full sm:w-auto md:w-52">Taste Feedback</label>
                          <textarea
                            name="test_remarks"
                            id="remarks"
                            placeholder='FeedBack'
                            value={formData.find(item => item.name === 'test_remarks')?.remarks || ''}
                            onChange={(e) => handlePlannedQtyChange(e, 9092)}
                            className="block w-full rounded-md border py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            rows="4" // Adjust the number of rows as needed
                          />
                        </div>
                        </div>
                        <div className=" p-2">
                          
                          <button
                            onClick={(e) => { handleSaveFeedback(e)}}
                            className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm mr-2 float-right"
                          >
                            Save FeedBack
                          </button>
                         
                          
                          
                        </div>
                      </div>
                      
                  </div>
                  <div className="p-6 bg-white rounded-lg shadow-md relative">
                    <div className="flex space-x-4 items-center mb-4">
                      {processStep.length > 0 && <h3 className="text-xl font-semibold">Standard Process Step</h3>}
                      <button
                      onClick={(e) => { handleProcessedit(e)}}
                      className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm">
                          Edit Process
                      </button> 
                    </div>
  
  <div className="space-y-3">
    {processStep.length > 0 ? (
      processStep.map((item, index) => (
        <div key={index} className="p-3 border rounded-md bg-gray-50">
          <span className="font-medium">Step {index + 1}:</span> {item.process}
        </div>
      ))
    ) : (
      <div className="text-gray-500">No process steps available.</div>
    )}
  </div>
</div>

                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ReceipePlanning;
