import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  baseUrl,
  timeList,
  generateRequisitionNumber,
} from "../comman/config";
import Select from "react-select";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { useNavigate, Link } from "react-router-dom";
import { DateSessionFilter } from "../components/Filter/DateSessionFilter";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from 'xlsx';
import Loader from "../components/Loader";


function KitRequisitionScreen() {
  const today = new Date().toISOString().split("T")[0];
  const [mealList, setMealList] = useState([]);
  const [rmDetailForItem, setRMDetailForItem] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [vesselList, setVesselList] = useState([]);
  const [ingredientList, setIngredientList] = useState([]);
  const [date, setDate] = useState(today);
  const [session, setSession] = useState("");
  const [formData, setFormData] = useState([{
    batch:  1,
    planned_batch_raw_qty: "",
    vessel_id: "",
    start_time: "",
    end_time:"",
    requisition_no: "",
    remarks: "",
    ingredientDetail: []

  }]);
  const [totalPlannedQty, setTotalPlannedQty] = useState(0);
  const [detail, setDetail] = useState([]);
  const role = localStorage.getItem('userRole');
  const [loading, setLoading] = useState(false) 

  useEffect(() => {
    getVesselList();
    getIngredientList();
  }, []);
  // useEffect(() => {
  //   let initialFormData;
  //   if(detail.length > 0){
  //     initialFormData = Array.from({ length: detail.length }, (_, index) => ({
  //         batch: index + 1,
  //         planned_batch_raw_qty: "",
  //         vessel_id: "",
  //         start_time: "",
  //         end_time:"",
  //         requisition_no: "",
  //         remarks: "",
  //         ingredientDetail: []
  //       }));
  //   }else{
  //     initialFormData = [{
  //       batch:  1,
  //       planned_batch_raw_qty: "",
  //       vessel_id: "",
  //       start_time: "",
  //       end_time:"",
  //       requisition_no: "",
  //       remarks: "",
  //       ingredientDetail: []
    
  //     }]
  //   }
  //       // Create initial formData and validationErrors based on mealList length
  //   console.log(initialFormData);
  //   // setFormData(initialFormData);
  // }, [detail]);

  useEffect(() => {
    if (mealList.length > 0 && activeIndex != null) {
      getRMDetailForItem();
    }
  }, [mealList, activeIndex]);

  useEffect(() => {
    if (mealList.length > 0 && activeIndex != null) {
      const itemId =
        mealList.find((item, index) => index === activeIndex)?.menu_item_id ??
        null;
      // console.log(itemId);
      const fetchDetail = async () => {
        setLoading(true)
        const condition = `menu_item_id='${itemId}' AND date = '${date}' AND session='${session.value}'`;
        const response = await axios.get(
          `${baseUrl}/comman/list?table=production_planning&condition=${condition}`
        );

        const RecipeData = await axios.get(`${baseUrl}/comman/list?table=receipe_planning&condition=${condition}`);
        const RecipeDbData = await axios.get(`${baseUrl}/comman/list?table=production_receipe_planning&condition=${condition} GROUP BY ingredient_id order by id asc`);
        let ddArray = [];
        // if(RecipeDbData.data.data.length > 0){
        //   RecipeDbData.data.data.forEach((item, index) => {
        //     const data = ingredientList.find((v) => v.id == item.ingredient_id);
           
        //     const stdQty = ((RecipeData.data?.data[index]?.std_qty ?? 0));
        //     const requiredQty = (RecipeData.data?.data[index]?.required_qty ?? 0)
        //     // console.log(stdQty);
        //     const dd = {ingredient_id : data.id, name : data.ingredient, unit: data.unit, stdQty: stdQty, required_qty : requiredQty, baseQty: 0, store : data.store}
        //     ddArray.push(dd);
        //   })
        // }else 
        if(RecipeData.data.data.length > 0){
          RecipeData.data.data.forEach((item) => {
            const data = ingredientList.find((v) => v.id == item.ingredient_id);
            // console.log(item);
            // const stdQty = (parseFloat(item.qty) / 50);
            
            if(data){
              const dd = {ingredient_id : data?.id ?? "", name : data.ingredient, unit: data.unit, stdQty: item.std_qty, required_qty : item.required_qty, baseQty: item.std_qty, store : data.store, itemDisable:false}
              ddArray.push(dd);
            }
            
            
          })
        }else{
          ddArray = [];
        }
       
        setDetail(response.data.data);
        const apiDetail = response.data.data;
        getDetailDispaly(apiDetail, itemId, ddArray);
        setLoading(false)
      };
      fetchDetail();
    }
  }, [mealList, activeIndex, rmDetailForItem]);

  const getDetailDispaly = async (detail, itemId, ddArray) => {
    try {
      if (detail) {
        const initialFormData = Array.from(
          { length: detail.length },
          (_, index) => ({
            batch: index + 1,
            planned_batch_raw_qty: "",
            vessel_id: "",
            start_time: "",
            end_time: "",
            requisition_no: "",
            remarks: "",
            ingredientDetail : ddArray
          })
        );
        console.log(initialFormData)
        const updatedFormData = [];
        // const updatedFormData = await Promise.all(initialFormData.map(async(initialItem) => {
          for (const initialItem of initialFormData) {
          const matchingObject = detail.find(
            (object) => object.batch === initialItem.batch
          );
          
          if (matchingObject) {
            const vesselId = matchingObject.vessel_id;
            const vesselItem = vesselList.find((item) => item.id == vesselId);
            const vesselObject = vesselItem
              ? { value: vesselItem.id, label: vesselItem.name }
              : { value: "", label: "" };

            const start_time = matchingObject.start_time;
            const timeslotItem = timeList.find(
              (item) => item.value == start_time
            );
            const timeslotObject = timeslotItem
              ? { value: timeslotItem.value, label: timeslotItem.label }
              : { value: "", label: "" };

            const end_time = matchingObject.end_time;
            const endTimeItem = timeList.find((item) => item.value == end_time);
            const endTimeSlotObject = endTimeItem ?  { value: endTimeItem.value, label: endTimeItem.label } : { value: "", label: "" };

            matchingObject.vessel_id = vesselObject;
            matchingObject.start_time = timeslotObject;
            matchingObject.end_time = endTimeSlotObject;
            matchingObject.requisition_no = generateRequisitionNumber(initialItem.batch,activeIndex,date,session,mealList,vesselList,vesselId);
            const frezzCondition = `menu_item_id='${itemId}' AND date = '${date}' AND session='${session.value}' AND batch = '${initialItem.batch}'`;
            const RecipeData = await axios.get(`${baseUrl}/comman/list?table=production_receipe_planning&condition=${frezzCondition}`);
            let updatedIngredientDetail;
            // if(RecipeData.data.data.length > 0){
            //    updatedIngredientDetail = RecipeData.data.data.map((item, index) => {
            //     const ingredientDetailItem = initialItem.ingredientDetail[index];
            //     // const updateStdQty = (ingredientDetailItem.stdQty * matchingObject.planned_batch_raw_qty).toFixed(3);
            //     const updateStdQty = ingredientDetailItem.stdQty;
            //     // console.log(ingredientDetailItem)
            //     return {ingredient_id : ingredientDetailItem.ingredient_id, name : ingredientDetailItem.name, unit: ingredientDetailItem.unit, stdQty: updateStdQty, required_qty : item.required_qty, baseQty: ingredientDetailItem.stdQty, store : ingredientDetailItem.store, db_id: item.id };
            //   })
            // }else{
              // const frezzCondition = `menu_item_id='${itemId}' AND date = '${date}' AND session='${session.value}' AND batch = '${initialItem.batch}'`;
              const checkFrezz = await axios.get(`${baseUrl}/comman/list?table=production_receipe_planning&condition=${frezzCondition}`);
              const itemDisable = checkFrezz.data.data.length > 0 ? true : false;
              // console.log(frezzCondition);
               updatedIngredientDetail = initialItem.ingredientDetail.map((item, index) => {
                // const stdQty = RecipeData.data.data[index]?.std_qty ?? item.stdQty;
                const total_qty = rmDetailForItem?.[0]?.planned_rm_qty ?? 0;
                const batch_qty = matchingObject?.planned_batch_raw_qty ?? 0;
                const stdQty = ((batch_qty * item.required_qty) / 100).toFixed(3);
                let requiredQty;

                const dbItem = RecipeData.data.data.find((val) => val.menu_item_id == matchingObject.menu_item_id && val.session == matchingObject.session && val.batch == matchingObject.batch && val.ingredient_id == item.ingredient_id);
                if(dbItem){
                  requiredQty = dbItem?.required_qty;
                }else{
                  // requiredQty = total_qty > 0 ? ((batch_qty * item.required_qty) / total_qty).toFixed(3) : 0;
                  requiredQty = ((batch_qty * item.required_qty) / 100).toFixed(3);
                }
                // const requiredQty = RecipeData.data.data[index]?.required_qty ?? item.required_qty;
                
                // const baseQty = RecipeData.data.data[index]?.baseQty ?? 0;
                // console.log(baseQty)
                return { ...item, stdQty: stdQty, required_qty: requiredQty , baseQty :stdQty,  db_id: dbItem?.id, itemDisable: role == 1 ? false : itemDisable };
              })
            
            
              updatedFormData.push({ ...initialItem, ingredientDetail: updatedIngredientDetail, ...matchingObject });
          }
           else {
            // console.log(initialItem.ingredientDetail);
          //   const updatedIngredientDetail = initialItem.ingredientDetail.map((item) => {
              
          //     const updateStdQty = item.stdQty;
          //     // console.log(updateStdQty);
          //     return { ...item, stdQty: updateStdQty, required_qty : updateStdQty, baseQty: updateStdQty };
          //   })
          //   return {...initialItem, ingredientDetail: updatedIngredientDetail};
          updatedFormData.push({...initialItem});
          }
        };
        setFormData(updatedFormData);
      } else {
       
        setTotalPlannedQty(0);
        const initialFormData = Array.from(
          { length: 1 },
          (_, index) => ({
            batch: index + 1,
            planned_batch_raw_qty: "",
            vessel_id: "",
            start_time: "",
            end_time:"",
            requisition_no: "",
            remarks: "",
            ingredientDetail: ddArray
          })
        );
        setFormData(initialFormData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const sum = formData.reduce((total, obj) => {
      const qty = parseFloat(obj.planned_batch_raw_qty);
      return isNaN(qty) ? total : total + qty;
    }, 0);
    setTotalPlannedQty(sum);
  }, [formData]);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const getVesselList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=vessel_master&condition=status=1`
    );
    setVesselList(response.data.data);
  };

  const getIngredientList = async () => {
    const response = await axios.get(`${baseUrl}/ingredients/list?limit=all`);
    const list = response.data.data.filter((item) => item.status == 1);
    setIngredientList(list);
  };

  const getRMDetailForItem = async () => {
    const item_id = mealList.find((item, index) => index == activeIndex);
    const condition = `date = '${date}' AND session = '${session.value}' AND menu_item_id = '${item_id.menu_item_id}'`;
    const response = await axios.get(
      `${baseUrl}/comman/list?table=raw_material&condition=${condition}`
    );
    setRMDetailForItem(response.data.data);
  };

  const handelFilterChange = async (date, session) => {
    setDate(date);
    setSession(session);
    if (date && session) {
      const condition = ` m.session='${session.value}' AND m.date='${date}'`;
      const response = await axios.get(
        `${baseUrl}/meal_master/item_list?condition=${condition}`
      );
      setMealList(response.data.data);
    }
  };

  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;
    if(name == "planned_batch_raw_qty"){
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        const updatedIngredientDetail = updatedFormData[index].ingredientDetail.map((item) => {
          const total_qty = rmDetailForItem?.[0]?.planned_rm_qty ?? 0;
          const batch_qty = value; 
          const requiredQty = total_qty > 0 ? ((batch_qty * item.stdQty) / total_qty).toFixed(3) : 0;
       
          return { ...item,  required_qty : requiredQty };
        })
        
        updatedFormData[index] = { ...updatedFormData[index], [name]: value, ingredientDetail:updatedIngredientDetail };
        // updatedFormData[index] = { ...updatedFormData[index], [name]: value};
        return updatedFormData;
      });
    }else{
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = { ...updatedFormData[index], [name]: value };
        return updatedFormData;
      });
    }
    
  };


  const handleSelectChange = async (index, data, option) => {
    if (option.name == "vessel_id") {
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          [option.name]: data,
          requisition_no: generateRequisitionNumber(
            updatedFormData[index].batch,
            activeIndex,
            date,
            session,
            mealList,
            vesselList,
            data.value
          ),
        };
        return updatedFormData;
      });
    } else {
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          [option.name]: data,
        };
        return updatedFormData;
      });
    }
  };

  const handlePlannedQtyChange = (e, formIndex, ingredientIndex) => {
    const { value } = e.target;
    
    setFormData((prevFormData) => {
      return prevFormData.map((batch, index) => {
          if (index + 1 === formIndex) {
              return {
                  ...batch,
                  ingredientDetail: batch.ingredientDetail.map((ingredient, idx) => {
                      if (idx === ingredientIndex) {
                          return {
                              ...ingredient,
                              required_qty: value
                          };
                      }
                      return ingredient;
                  })
              };
          }
          return batch;
      });
    });
  };
  const handleSave = async (e, index) => {
    e.preventDefault();

    const menu_item_id =
      mealList.find((item, i) => i == activeIndex)?.menu_item_id ?? 0;
    const combineData = {
      formData: formData[index],
      date: date,
      session: session,
      menu_item_id: menu_item_id,
    };
    console.log(combineData);
    try {
      // setPopupOpen(true);

      await axios.post(`${baseUrl}/production_planning/batch`, combineData);
      // toast.success("Data successfully saved!");
    } catch (error) {
      toast.error("Failed to submit the form.");
      // setPopupOpen(false);
      console.log(error);
    }
  };

  const handleSubmitRequirement = async(e, index) => {
   
    e.preventDefault();
    if(window.confirm(` 
      આ કીટ ઇસ્યુ થવા જઈ રહી છે. હવે પછી કીટ માં કોઈ સુધારો થઇ શકશે નહી.
      કોઈ કારણસર કોઈ સુધારો કરવાનો થાય તો સ્ટોર ને જાણ કરવી.
      શું તમે કીટ ઇસ્યુ કરવા માંગો છો ?`)
    ){
      const menu_item_id = mealList.find((item, i) => i == activeIndex)?.menu_item_id ?? 0;
      const combineData = {
        formData: formData[index],
        date: date,
        session: session,
        menu_item_id: menu_item_id,
      };
      // console.log(combineData);
      try {
        // setPopupOpen(true);

        await axios.post(`${baseUrl}/production_planning/submit_requirement`, combineData);
        toast.success("Data successfully saved!");
      } catch (error) {
        toast.error("Failed to submit the form.");
        // setPopupOpen(false);
        console.log(error);
      }
    }

  }
  const handleExport = async (e, index) => {
    e.preventDefault();

    const menu_item = mealList.find((item, i) => i === activeIndex);
    const wb = XLSX.utils.book_new();

    // Group ingredients by store
    const ingredientsByStore = {};
    formData[index].ingredientDetail.forEach(item => {
        const storeName = item.store?.name || 'Main Store';
        if (!ingredientsByStore[storeName]) {
            ingredientsByStore[storeName] = [];
        }
        ingredientsByStore[storeName].push(item);
    });

    // Create sheets for each store
    Object.entries(ingredientsByStore).forEach(([storeName, items]) => {
        const ws = XLSX.utils.aoa_to_sheet([
            [""], // Placeholder for merged cell
            [`Estimation Count: ${rmDetailForItem?.[0]?.estimated_count ?? 0}`],
            [`Requisition No.: ${formData[index].requisition_no}`],
            ["Date :", date, "Session :", session.label, "Item :", menu_item.item_name],
            ["Batch :", formData[index].batch, "Vessel :", formData[index].vessel_id?.label, "Plan Batch Qty :", formData[index].planned_batch_raw_qty],
            [],
            [],
            ["Store:", storeName], // Add store name
            ["Sr. No.", "Ingredients", "Unit", "જરૂરી (req.)", "મોકલેલ (Issued)","મળેલ (Recd.)", "બચેલું (Excess)", "વપરાયેલ (Consumed)", "રીટર્ન (Return)"],
            ...items.map((item, idx) => [
                idx + 1,
                item.name,
                item.unit?.name,
                item.required_qty,
                item.required_qty,
                "",
                "",
                "",
                ""
            ])
        ]);

        // Merge cells as needed
        ws['!merges'] = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
            { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
            { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } },
            { s: { r: 3, c: 4 }, e: { r: 3, c: 5 } },
            { s: { r: 4, c: 4 }, e: { r: 4, c: 5 } },
            { s: { r: 3, c: 6 }, e: { r: 3, c: 7 } },
            { s: { r: 4, c: 6 }, e: { r: 4, c: 7 } },
            { s: { r: 6, c: 0 }, e: { r: 6, c: 7 } } // Merge store name cell
        ];

        // Set cell values and styles
        ws['A1'] = {
            v: "Raw Material Requisition & Issue Slip( Store / Support )",
            s: {
                alignment: { horizontal: "center" },
                font: { sz: 20, color: { rgb: "000000" } },
                fill: { patternType: "solid", fgColor: { rgb: "D8D8D8" } }
            }
        };

        // ws['A3'] = { v: "Requisition No." };
        // ws['B3'] = {
        //     v: formData[index].requisition_no,
        //     s: {
        //         alignment: { horizontal: "center" },
        //         font: { sz: 12, color: { rgb: "000000" } }
        //     }
        // };
        ws['A3'] = {
          v: `Requisition No.: ${formData[index].requisition_no}`,
          s: {
              alignment: { horizontal: "center" },
              font: { sz: 12, color: { rgb: "000000" } },
              fill: { patternType: "solid", fgColor: { rgb: "FFFF00" } }
          }
      };

        ws['G4'] = { v: menu_item.item_name };
        ws['G5'] = { v: formData[index].planned_batch_raw_qty };

        const wscols = [
            { wch: 10 }, // Reduced width for Date and Batch columns
            { wch: 10 }, // Requisition No. column
            { wch: 10 }, // Date column
            { wch: 10 }, // Session column
            { wch: 10 }, // Item column
            { wch: 10 }, // Batch column
            { wch: 10 }, // Vessel column
            { wch: 10 }, // Plan Batch Qty column
            { wch: 5 },  // Ingredient ID column
            { wch: 10 }, // Name column
            { wch: 5 },  // Unit column
            { wch: 10 }, // Std Qty column
            { wch: 10 }, // Planned Qty column
        ];
        ws['!cols'] = wscols;

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, `Store_${storeName}`);
    });
    const filename = formData?.[index]?.requisition_no.replace(/\//g, "-");

    // Generate XLSX file and trigger download
    XLSX.writeFile(wb, `${filename}_Issue_Slip.xlsx`);
};


  return (
    <div className="">
      {loading && <Loader/>}
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">
        Kit Requisition Planning
        </h1>
        <Toaster position="top-center" reverseOrder={false} />
        {/* <div>
                    <Link to="/menu/add">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md placeholder:text-sm mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Add Menuitem</button>
                    </Link>
                </div> */}
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <DateSessionFilter handleFilterChange={handelFilterChange} />
        <div>
          {mealList.map((item, index) => (
            <div key={index} className="border-b mt-4">
              <div
                className="flex justify-between items-center p-4 cursor-pointer accordion-title bg-orange-400 border rounded"
                onClick={() => handleClick(index)}
              >
                <span>{`${item.item_name} (${item.eng_name})`}</span>
                {index === activeIndex ? (
                  <ChevronUpIcon className="w-6 h-6" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6" />
                )}
              </div>
              {index === activeIndex && (
                <div className="space-y-2 p-4 bg-white">
                  <div className="flex justify-between">
                    <div className="lg:w-96">
                      <div className="flex flex-wrap justify-between">
                        <div className="sm:text-sm lg:text-base font-bold">
                          Estimation Count:
                        </div>
                        <div>{rmDetailForItem?.[0]?.estimated_count ?? 0}</div>
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <div className="sm:text-sm lg:text-base font-bold">
                          Ras - Qty / 100 Persons (kgs.)
                        </div>
                        <div>
                          {rmDetailForItem?.[0]?.ras_qty_per_100_person ?? 0}
                        </div>
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <div className="sm:text-sm lg:text-base font-bold">
                          Planned RM Qty (kgs):
                        </div>
                        <div>{rmDetailForItem?.[0]?.planned_rm_qty ?? 0}</div>
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <div className="sm:text-sm lg:text-base font-bold">
                          Pending Planned Qty. (kgs.) :
                        </div>
                        <div>
                          {isNaN(
                            rmDetailForItem?.[0]?.planned_rm_qty - totalPlannedQty
                          )
                            ? 0
                            : rmDetailForItem?.[0]?.planned_rm_qty -
                              totalPlannedQty}
                        </div>
                      </div>
                    </div>
                   
                  </div>
                  <div className="flex overflow-x-auto">
                    <div className="flex mt-10 flex-col">
                      <div className="w-32 font-bold mt-1">Batch</div>
                      <div className="w-52 font-bold mt-6">
                        Planned Batch Raw Qty
                      </div>
                      <div className="w-52 font-bold mt-7">Vessel Name<span className="text-red-500">*</span></div>
                      <div className="w-52 font-bold mt-8">Time Slot</div>
                      <div className="w-52 font-bold mt-8">Kit No.</div>
                      <div className="w-52 font-bold mt-7   ">
                        Remarks (stores)
                      </div>
                      <div className="w-20 font-bold "></div>
                    </div>
                    {Array.from({ length: detail?.length ?? 1 }, (_, index) => (
                      <div
                        key={index}
                        className="gap-5 mb-4 mt-10 ms-2 flex-shrink-0 "
                      >
                        <div className="border p-2">{index + 1}</div>
                        <div className="border p-2">
                          <input
                            type="number"
                            step="0.01"
                            id="planned_batch_raw_qty"
                            placeholder="Planned Batch Raw Qty"
                            name="planned_batch_raw_qty"
                            value={formData?.[index]?.planned_batch_raw_qty}
                            onChange={handleInputChange(index)}
                            // disabled={formData?.[index]?.ingredientDetail.some((item) => item.itemDisable == true)}
                            disabled={true}
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}}
                            className="w-full p-1 border rounded-md placeholder:text-s"
                          />
                        </div>
                        <div className="border p-2">
                          <Select
                            classNamePrefix="select"
                            name="vessel_id"
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: "0.80rem",
                              }),
                              control: (provided) => ({
                                ...provided,
                                // backgroundColor: "#FFFAF0",
                                fontSize: "16px",
                              }),
                            }}
                            value={formData?.[index]?.vessel_id}
                            onChange={(selectedOption, name) => {
                              handleSelectChange(index, selectedOption, name);
                            }}
                            placeholder="Vessel Name"
                            options={vesselList.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                            // isDisabled={formData?.[index]?.ingredientDetail.some((item) => item.itemDisable == true)}
                            isDisabled={true}
                            filterOption={(option, inputValue) => {
                              return option.data.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                          />
                        </div>
                        <div className="border p-2 flex">
                          
                          <Select
                            classNamePrefix="select"
                            name="start_time"
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: "0.80rem",
                              }),
                              control: (provided) => ({
                                ...provided,
                                // backgroundColor: '#FFFAF0', 
                                fontSize: '16px', 
                              }),
                            }}
                            value={formData?.[index]?.start_time}
                            onChange={(selectedOption, name) => {
                              handleSelectChange(index, selectedOption, name);
                            }}
                            placeholder="Start Time"
                            className="placeholder:text-sm ms-2 w-48"
                            options={timeList.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                            // isDisabled={formData?.[index]?.ingredientDetail.some((item) => item.itemDisable == true)}
                            isDisabled={true}
                            filterOption={(option, inputValue) => {
                              return option.data.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                          />
                          <Select
                            classNamePrefix="select"
                            name="end_time"
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: "0.80rem",
                              }),
                              control: (provided) => ({
                                ...provided,
                                // backgroundColor: '#FFFAF0', 
                                fontSize: '16px', 
                              }),
                            }}
                            value={formData?.[index]?.end_time}
                            onChange={(selectedOption, name) => {
                              handleSelectChange(index, selectedOption, name);
                            }}
                            placeholder="End Time"
                            className="placeholder:text-sm ms-2 w-48"
                            options={timeList.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                            // isDisabled={formData?.[index]?.ingredientDetail.some((item) => item.itemDisable == true)}
                            isDisabled={true}
                            filterOption={(option, inputValue) => {
                              return option.data.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                          />
                        </div>
                        <div className="border p-2">
                          <input
                            type="text"
                            placeholder="Requisition No."
                            disabled
                            id="requisition_no"
                            name="requisition_no"
                            value={formData?.[index]?.requisition_no}
                            onChange={handleInputChange(index)}
                            className="w-full p-1 border rounded-md placeholder:text-sm"
                          />
                        </div>
                        <div className="border p-2">
                          <input
                            type="text"
                            placeholder="Remarks"
                            id="remarks"
                            name="remarks"
                            value={formData?.[index]?.remarks === 'null' || formData?.[index]?.remarks === null ? '' : formData?.[index]?.remarks}
                            onChange={handleInputChange(index)}
                            disabled={formData?.[index]?.ingredientDetail.some((item) => item.itemDisable == true)}
                            className="w-full border rounded-md p-1 placeholder:text-sm bg-orange-50"
                          />
                        </div>
                        {!(formData?.[index]?.ingredientDetail.length > 0) && <div className="border p-2">
                            <p className="font-bold text-center bg-red-100">Please save Recipe in Recipe Plannng Screen</p>
                          </div>}
                        {formData?.[index]?.ingredientDetail.length > 0 && <div className="border p-2">
                            <table className="border space-x-1">
                              <thead className="space-x-1">
                                <tr>
                                  <th className="px-3">Ingredients</th>
                                  <th className="px-3">Units</th>
                                  <th className="px-3">Recipe Qty</th>
                                  <th className="px-3">Kit Reqd Qty</th>
                                </tr>
                              </thead>
                              <tbody>
                              {formData[index].ingredientDetail.map((item, idx) => (
                                <tr key={idx} className="border">
                                  <td className="text-center">{item.name}</td>
                                  <td className="text-center">{item.unit?.name ?? "-"}</td>
                                  <td className="text-center">{item.stdQty}</td>
                                  <td className="text-center p-1"><input type="number" disabled={item.itemDisable} onWheel={(e) => e.target.blur()} onKeyDown={(e) => {if (e.key === 'ArrowUp' || e.key === 'ArrowDown') { e.preventDefault(); }}} step="0.01" id={`planned_qty_${index}_${idx}`} placeholder="Planned Qty"  name={`planned_qty_${index}_${idx}`} value={item.required_qty} onChange={(e) => handlePlannedQtyChange(e, index+1, idx)} className={`w-24 p-1 border rounded-md placeholder:text-sm ${item.itemDisable ? "" : "bg-orange-50"}`}/></td>
                                </tr>
                              ))}
                              </tbody>
                            </table>
                        </div>}
                        {formData?.[index]?.ingredientDetail.length > 0 && <div className="border p-2">
                          {/* <button
                            onClick={(e) => {
                              handleSave(e, index);
                            }}
                            className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm mr-2 float-right"
                          >
                            Save
                          </button> */}
                          {!formData?.[index]?.ingredientDetail.some((item) => item.itemDisable == true) && <button
                            onClick={(e) => {
                              handleSave(e, index);
                              handleSubmitRequirement(e, index);
                            }}
                            disabled={formData?.[index]?.ingredientDetail.some((item) => item.itemDisable == true)}
                            className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm mr-2 float-right"
                          >
                            Submit Requirement
                          </button>}
                          <button
                            onClick={(e) => {
                              handleExport(e, index);
                            }}
                            className="px-4 py-2 bg-blue-500 text-white rounded-md placeholder:text-sm mr-2"
                          >
                            Export
                          </button>
                        </div>}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default KitRequisitionScreen;
