import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import {baseUrl } from "../../../comman/config";
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';

export default function Form({detail}) {
    const [categoryList, setCategoryList] = useState([]);
    const [formData, setFormData] = useState({
        maharaj_name: '',
        categories:[]
    });
    const [validationErrors, setValidationErrors] = useState({
        maharaj_name: false

    });
    const [popupOpen , setPopupOpen] = useState(false);
    const formRef = useRef(null);
    
    
    const navigate = useNavigate();
    
    useEffect(()=>{
        getCategoryList();
    },[])

    useEffect(()=>{
        console.log(formData)
    },[formData])

    useEffect(() => {
        if(detail){
            setFormData({
                maharaj_name : detail[0].maharaj_name || '',
                categories:[]
            });
            getMaharajItemCategoryList()
        }
        // console.log(detail[0]);
    }, [detail]);
    

    const getCategoryList = async () => {
        // console.log(page);
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/item/category/list`, {
           
        });
        console.log(response.data.data)
        setCategoryList(response.data.data);
    }

    const getMaharajItemCategoryList = async () => {
        setPopupOpen(true)
        const response = await axios.get(`${baseUrl}/maharaj/item-category-list/${detail[0].id}`, {
           
        });
        console.log(response.data.data)
        const ids = response.data.data.map(category => category.id)
        setFormData((prevData) => ({...prevData,categories:ids}))
        setPopupOpen(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const newValidationErrors = {
            maharaj_name : formData.maharaj_name === ''
        };
        setValidationErrors(newValidationErrors);
        const hasErrors = Object.values(newValidationErrors).some((error) => error);
        console.log(formData);
        if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }else {
            let url;
            try {
                setPopupOpen(true);
                if(detail){
                    url = `maharaj/edit/${detail[0].id}`;
                }else{
                    url = "maharaj/add";
                }
                await axios.post(`${baseUrl}/${url}`,{
                    formData: formData,
                });
                setPopupOpen(false);
                navigate("/maharaj_master/list");
            } catch (error) {

                setPopupOpen(false);
                console.log(error);
                
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
    
            }));
      
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };

    const handleChangeMultiSelect = (selected, name) => {
        if(name === 'categories'){
            const selectedIds = selected ? selected.map(option => option.value) : []; 
            setFormData((prevData) => ({...prevData,[name]:selectedIds}))
        }
    };

  return (
 
 <form ref={formRef}>
                <div className="space-y-12">
                    <div>
                        <div className="mt-10 space-y-8 lg:space-y-0 lg:flex lg:gap-x-5 lg:flex-wrap ">
                            <div className="sm:col-span-2">
                                <label htmlFor="menuitem_eng" className="block text-sm font-medium leading-6 text-gray-900">Maharaj Name</label>
                                <div className="mt-2">
                                    <input
                                      type="text"
                                      name="maharaj_name"
                                      id="maharaj_name"
                                      placeholder='Maharaj Name'
                                      value={formData.maharaj_name}
                                      onChange={handleInputChange}
                                      className={`block w-full rounded-md border ${validationErrors.maharaj_name ? 'border-red-500' : 'border-gray-300'} py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                                    />
                                </div>
                                {validationErrors.maharaj_name && <div className="text-red-500">Please enter a maharaj name.</div>}
                            </div>
                        </div>
                        <div className="mt-10 space-y-8 lg:space-y-0 lg:flex lg:gap-x-5 lg:flex-wrap ">
                            <div className='sm:col-span-2'>
                                <label htmlFor="menuitem_eng" className="block text-sm font-medium leading-6 text-gray-900">Item Categories</label>
                                <div className="mt-2">
                                    <Select
                                        isMulti
                                        classNamePrefix="select"
                                        className='basic-multi-select'
                                        value={categoryList.filter(item => formData.categories?.includes(item.id)).map(item => ({
                                            value: item.id,
                                            label: `${item.name} (${item.eng_name})`
                                        }))}
                                        name="categories"
                                        onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions, 'categories')}
                                        options={categoryList.map(item => ({
                                            value: item.id,
                                            label: `${item.name} (${item.eng_name})`
                                        }))}
                                        placeholder="Category List"
                                        filterOption={(option, inputValue) => {
                                            return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                                        }}
                                    />  
                                </div>
                            </div>
                        </div>

                       
                        
                    </div>
                </div>
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <Link to="/maharaj_master/list">
                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                    </Link>
                    <button
                    onClick={handleSubmit}
                      type="submit"
                      className="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                </div>
            </form>
            )
        }