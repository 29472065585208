
import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import ReceipeForm from "../../../components/Form/Master/ReceipeForm";


export default function AddReceipe() {
    const location = useLocation();
    const detail = location.state;
    const handelIngredientList = (list) => {
        // setIngredientList(list);
    }
  return (
    <div className="p-4">
        <div className="flex justify-between items-center py-4 px-6">
            <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">Recipe Add</h1>
            <div>
                <a href="/ingredients/add" target='_blank'>
                    <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Add Ingredient</button>
                </a>
                <Link to="/receipe/list">
                    <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Back</button>
                </Link>
            </div>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg px-4 py-2">
            <ReceipeForm detail={detail} handelIngredientList={handelIngredientList} duplicate={detail ? true : false} />
        </div>
    </div>
  )
}
