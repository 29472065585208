import React from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import AppRouter from './routes';
import { HelmetProvider } from 'react-helmet-async';
import Sidebar from './components/Sidebar';
import { TitleProvider } from './routes/TitleContext';

const Layout = () => {
  const location = useLocation();
  const isMasterReport = location.pathname.startsWith('/reports') || location.pathname.startsWith('/login');
  return (
    <div>
      {!isMasterReport && <Sidebar />}
      <main className={`p-4 ${!isMasterReport ? 'sm:ml-64' : ''}`}>
        <AppRouter />
      </main>
    </div>
  );
};

function App() {
 
  return (
    <HelmetProvider>
    <Router>
      <TitleProvider>
        <Layout />
      </TitleProvider>
    </Router>
    </HelmetProvider>
  );
}

export default App;
