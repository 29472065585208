
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Select from 'react-select';
import {baseUrl, limit} from "../../../comman/config";
import { PencilIcon, XCircleIcon } from '@heroicons/react/outline';
import { useNavigate, Link } from 'react-router-dom';
// import Pagination from "react-js-pagination";

function List() {

    const [receipeList, setReceipeList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [item, setItem] = useState([]);
    const [maharaj, setMharaj] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [maharajList, setMaharajList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false);
    const [filter, setFilter] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        
        getItemList();
        getMaahrajList();
    }, []);

    useEffect(() => {
        getReceipe();
    }, [item, maharaj]);

    useEffect(() => {
        let filterelist;
        if(searchQuery){
            filterelist = receipeList.filter(item => {
                return item.eng_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                       item.item_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                       item.category.toLowerCase().includes(searchQuery.toLowerCase())  ||
                       item?.maharaj?.maharaj_name.toLowerCase().includes(searchQuery.toLowerCase());;
            });
        } else{
            filterelist = receipeList;
        }  
        setFilteredList(filterelist);
    }, [searchQuery]);
    const getReceipe = async () => {
        // console.log(page);
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/receipe/list?page=${page}&limit=${limit}&item=${item.value}&maharaj=${maharaj.value}`, {
           
        });
        console.log(response.data.data)
        setPopupOpen(false);
        setReceipeList(response.data.data);
        setFilteredList(response.data.data);
        setTotalItem(response.data.totalItem);
    }

    const handelFilterChange= (e) => {
        const { value } = e.target;
        setSearchQuery(value);
    };
    const getItemList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=item_master&condition=status=1`);
        const itemList = response.data.data;
        // itemList.unshift({id:"", item_name:'Item List'});
        setItemList(itemList);
    }
    
    const getMaahrajList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=maharaj_master&condition=status=1`);
        const itemList = response.data.data;
        // itemList.unshift({id:"", item_name:'Item List'});
        setMaharajList(itemList);
    }

    const editReceipeitem = async (id,maharaj_id) => {
        navigate(`/receipe/edit/${btoa(id)}`, { state: receipeList.filter(item => item.id == id && item.maharaj?.id == maharaj_id) });
    }

    const handleSelectChange = (selected, name) => {
        if(name === 'item'){
            setItem(selected);
            
        }else if(name === 'maharaj'){
            setMharaj(selected);
            
        }
        // getReceipe();
    };

    // const handleSubmit = () => {
        
    //     setFilter(true);
    // };

    const handleClear = () => {
        // handleFilterChange("", "");
       
        setItem("");
        setMharaj("");
    }
   


    return (
        <div className="p-4">
            <div className="flex justify-between items-center py-4 px-6">
                <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">Receipe List</h1>
                <div>
                    <Link to="/receipe/add">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Add Receipe</button>
                    </Link>
                </div>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center pb-4">
            
           
            <div className='m-5'>
                <Select
                    
                    classNamePrefix="select"
                    className='basic-multi-select'
                    value={item}
                    name="item"
                    onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'item')}
                    options={itemList.map(item => ({
                        value: item.id,
                        label: `${item.item_name} ${item.eng_name ? `(${item.eng_name})` : ''}`
                    }))}
                    placeholder="Item List"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
            </div>
            <div className='m-5'>
                <Select
                    
                    classNamePrefix="select"
                    className='basic-multi-select'
                    value={maharaj}
                    name="maharaj"
                    onChange={(selectedOptions) => handleSelectChange(selectedOptions, 'maharaj')}
                    options={maharajList.map(item => ({
                        value: item.id,
                        label: item.maharaj_name
                    }))}
                    placeholder="Maharaj List"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
            </div>
            
            <div className='m-5'>

                {(item?.value || maharaj?.value) && 
                 <button
                 onClick={handleClear}
                 className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
             >
                 Clear
             </button>} 
             </div>
            
        </div>

                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">Menuitem name</th>
                            <th scope="col" className="px-6 py-3">Category</th>
                            <th scope="col" className="px-6 py-3">Maharaj name</th>
                            <th scope="col" className="px-6 py-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {filteredList.map((item) => (
                        <tr key={`${item.id} ${item.maharaj?.id}`} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.item_name} ({item.eng_name})</th>
                            <td className='px-6 py-4'>{item.category}</td>
                            <td className="px-6 py-4">{item?.maharaj?.maharaj_name ?? "-"}</td>
                            <td className="px-6 py-4 flex">
                                    <a 
                                    onClick={() => editReceipeitem(item.id,item?.maharaj?.id)}
                                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-2">
                                        <PencilIcon className="w-4 h-4 inline-block" /> 
                                    </a>
                                    
                            </td>
                        </tr>))}
           
                    </tbody>
                </table>
            </div>
        </div>
  );
}

export default List;