
import React from 'react'
import { Link } from 'react-router-dom';
import EventForm from "../../../components/Form/Master/EventForm";


export default function AddEventMaster() {
   
   
  return (
    <div className="p-4">
        <div className="flex justify-between items-center py-4 px-6">
            <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">Event Add</h1>
            <div>
                <Link to="/maharaj_master/add" target='_blank'>
                    <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Add Maharaj</button>
                </Link>
                <Link to="/bowl_master/add" target='_blank'>
                    <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Add Bowl Type</button>
                </Link>
                <Link to="/event_master/list">
                    <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Back</button>
                </Link>
            </div>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg px-4 py-2">
            <EventForm />
        </div>
    </div>
  )
}
