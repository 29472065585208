
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import {baseUrl, limit, formatDate} from "../../comman/config";
import { PencilIcon } from '@heroicons/react/outline';
import { useNavigate, Link } from 'react-router-dom';
// import Pagination from "react-js-pagination";

function List() {

    const [dishIssueList, setDishIssueList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        getdishIssueList();
        
    }, []);

    useEffect(() => {
        let filterelist;
        if(searchQuery){
            filterelist = dishIssueList.filter(item => {
                return item.session_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.date.toLowerCase().includes(searchQuery.toLowerCase());
            });
        } else{
            filterelist = dishIssueList;
        }  
        setFilteredList(filterelist);
    }, [searchQuery]);


    const getdishIssueList = async () => {
        // console.log(page);
        setPopupOpen(true);
        const response = await axios.get(`${baseUrl}/dish_issue/list?page=${page}&limit=${limit}`, {
           
        });
        setPopupOpen(false);
        setDishIssueList(response.data.data);
        setFilteredList(response.data.data);
        setTotalItem(response.data.totalItem);
    }

    const handelFilterChange= (e) => {
        const { value } = e.target;
        setSearchQuery(value);
    };



    const editDishIssue = async (item) => {
        // console.log(id);
        navigate(`/dish_issue/edit/${btoa(item)}`, { state: dishIssueList.filter(i => {return i.date == item.date && i.session_name == item.session_name}) });
    }

   


    return (
        <div className="p-4">
            <div className="flex justify-between items-center py-4 px-6">
                <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">Dish Issue List</h1>
                {/* <div>
                    <Link to="/dish_issue/add">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Add Dish Issue</button>
                    </Link>
                </div> */}
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="pb-4 flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between">
                    <label htmlFor="table-search" className="sr-only">Search</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
                            <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                        </div>
                        <input type="text" value={searchQuery} onChange={handelFilterChange} id="table-search" className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items" />
                    </div>
                </div>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                        
                            <th scope="col" className="px-6 py-3">Date</th>
                            <th scope="col" className="px-6 py-3">Session</th>
                            {/* <th scope="col" className="px-6 py-3">Item Name</th>
                            <th scope="col" className="px-6 py-3">Dish Issue</th>
                            <th scope="col" className="px-6 py-3">Remarks</th> */}
                            <th scope="col" className="px-6 py-3">Coupons Sold</th>
                            <th scope="col" className="px-6 py-3">Dish Issued -API</th>
                            <th scope="col" className="px-6 py-3">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {filteredList.map((item, index) => (
                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td className="px-6 py-4">{formatDate(item.date)}</td>
                            <td className="px-6 py-4">{item.session_name}</td>
                            {/* <td className="px-6 py-4">{`${item.item_name} (${item.eng_name})`}</td>
                            <td className="px-6 py-4">{item.dish_issue}</td>
                            <td className="px-6 py-4">{item.remarks}</td> */}
                            <td className="px-6 py-4">{item.coupons_sold ?? '-'}</td>
                            <td className="px-6 py-4">{item.dish_issued_api ?? '-'}</td>
                            <td className="px-6 py-4 flex">
                                    <a 
                                    onClick={() => editDishIssue(item)}
                                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline mr-2">
                                        <PencilIcon className="w-4 h-4 inline-block" /> 
                                    </a>
                                    {/* <a href="#" onClick={() => deleteVessel(item.id)} className="font-medium text-red-600 dark:text-red-500 hover:underline">
                                        <XCircleIcon className="w-4 h-4 inline-block" /> 
                                    </a> */}
                                </td>
                        </tr>))}
           
                    </tbody>
                </table>
            </div>
        </div>
  );
}

export default List;