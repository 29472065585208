
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import {baseUrl, limit, formatDate} from "../../comman/config";
import Loader from '../../components/Loader'
import * as XLSX from 'xlsx';
import { DateSessionReportFilter } from "../../components/Filter/DateSessionReportFilter";

function List() {

    const today = new Date().toISOString().split("T")[0];    
    const [filteredList, setFilteredList] = useState([]);
    const [reportList, setReportList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [session, setSession] = useState("");
    const [item, setItem] = useState("");
    const [method, setMethod] = useState("");
    const [summary, setSummary] = useState(true);
    const [reportType, setReportType] = useState(1);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        
            getShortageList(); 
        
    }, [fromDate,toDate, session, item, method]);


    useEffect(() => {
        let filterelist;
        if(method && method.length > 0){
            const methodIds = method.map(method => method.value);
            filterelist = reportList.filter((item) => methodIds.includes(item.measurements));
        } else{
            filterelist = reportList;
        }  
        setFilteredList(filterelist);
    }, [ method, reportList]);
    const getShortageList = async () => {
        
        setPopupOpen(true);
        setLoading(true)
        const response = await axios.get(`${baseUrl}/reports/master_report?page=${page}&limit=${limit}&fromDate=${fromDate}&toDate=${toDate}&session=${session?.value}&item=${JSON.stringify(item)}&method=${JSON.stringify(method)}`, {
           
        });
        console.log(response.data.data)
        setLoading(false)
        setPopupOpen(false);
        setReportList(response.data.data);
        setFilteredList(response.data.data);
        setTotalItem(response.data.totalItem);
    }

    const handelFilterChange= (fromDate, toDate, session, item, method) => {
        console.log(item);
        setFromDate(fromDate);
        setToDate(toDate);
        setSession(session);
        setItem(item);
        setMethod(method);
        
        
    };

    // const hadelSummary = (summary) => {
    //     setSummary(summary);
    // }

    const handelReportChange = (data) => {
        console.log(data);
        setReportType(data.value);
    }
   
    const handleExport = () => {
        const header = [
            "Date", "Session", "Menu Item", "Actual Raw Qty.", "Finished Qty", 
            "Total no. Rotis", "RM to FG Ratio (Multiplier)", "Gulla weight", 
            "Avg. weight of 1 finished roti", "No of rotis in 1 icebox", 
            "No. of Icebox", "RM to No.of Roti Ratio", "RM to No.of icebox Ratio"
        ];
        

        const data = filteredList.map((item) => [
            formatDate(item.date),
            item.session_name,
            item.eng_name,
            item.batch_raw_material_qty,
            item.roti_finished_qty ?? 0,
            item.finishedQty ?? 0,
            item.batch_raw_material_qty > 0 ? (item.roti_finished_qty / item.batch_raw_material_qty).toFixed(3) : 0,
            item.gulla_weight,
            item.avg_weight_of_1_finished_roti,
            item.nos_of_rotis_1_icebox,
            item.no_of_actual_bowls,
            item.current_rm_to_fg_ratio ?? 0,
            item.current_rm_to_bowl_ratio
        ]);
    
        const worksheet = XLSX.utils.aoa_to_sheet([header, ...data]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Rotli Bhakhri Production Report");

        XLSX.writeFile(workbook, `Rotli_Bhakhri_Prod_Report_${fromDate ? '_' + formatDate(fromDate) : ''}to${toDate ? '_' + formatDate(toDate) : ''}${session?.label ? '_' + session.label : ''}.xlsx`);
    }
    
   
      


    return (
        <div className="p-4">
            {loading && <Loader/>}
            <div className="flex justify-between items-center py-4 px-6">
                <h1 className="text-2xl font-bold">Rotli / Bhakhri Production Report</h1>
                <div>
                    <Link to="/">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Back</button>
                    </Link>
                </div>
            </div>
            <div className="relative shadow-md sm:rounded-lg">
            <DateSessionReportFilter 
            handleFilterChange={handelFilterChange} 
            // hadelSummary={hadelSummary} 
            handleExport={handleExport}
            handelReportChange={handelReportChange} 
    
            />


                <div className="overflow-x-auto">
                <table className="w-full  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                        
                            <th scope="col" className="px-2 py-1 border border-black text-center ">Date</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Session</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Menu Item</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Actual Raw Qty.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Finished Qty  </th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Total no. Rotis</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">RM to FG Ratio (Multiplier)</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Gulla weight</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Avg. weight of 1 finished roti</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">No of rotis in 1  icebox</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">No. of Icebox</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">RM to No.of Roti Ratio</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">RM to No.of icebox Ratio</th>
                            </tr>
                    </thead>
                    <tbody>
                    {filteredList.map((item, index) => (
                        // <tr key={index} className={`${item.summary ? 'bg-lime-200' : (item.methodName?.id == 1 ? 'bg-blue-200' : (item.methodName?.id == 2 ? 'bg-orange-200' : 'bg-white dark:bg-gray-800 dark:border border-black-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 '))} border border-black-b`}>
                        <tr key={index} className={`${(item.methodName?.id == 1 ? 'bg-blue-200' : (item.methodName?.id == 2 ? 'bg-orange-200' : 'bg-lime-200 '))} border border-black-b`}>
                            <td className="px-2 py-1 border border-black text-center">{formatDate(item.date)}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.session_name}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.eng_name}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.batch_raw_material_qty}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.roti_finished_qty ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.finishedQty ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.batch_raw_material_qty > 0 ? (item.roti_finished_qty/item.batch_raw_material_qty).toFixed(3) : 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.gulla_weight}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.avg_weight_of_1_finished_roti ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.nos_of_rotis_1_icebox ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.no_of_actual_bowls}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.current_rm_to_fg_ratio ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.current_rm_to_bowl_ratio}</td>
                            
                        </tr>))}
           
                    </tbody>
                </table>
                </div>
            </div>
        </div>
  );
}

export default List;