import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import {baseUrl , reportTypeList} from "../../comman/config";

const DateSessionReportFilter = ({ handleFilterChange, handleExport, handelReportChange }) => {
    const today = new Date().toISOString().split('T')[0];
    const formattedFromDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
    const [fromDate, setFromDate] = useState(today);
    const [toDate, setToDate] = useState(today);
    const [session, setSession] = useState("");
    const [reportType, setReportType] = useState("");
    const [item, setItem] = useState([]);
    const [method, setMethod] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [methodList, setMethodList] = useState([]);
    const [filter, setFilter] = useState(false);
    const [eventList, setEventList] = useState([]);
    const [event, setEvent] = useState({});
    // const [summary, setSummary] = useState(false);
    const location = useLocation();
    const routeName = location.pathname.split('/').pop(); 
    

    useEffect(() => {
        getSessionList();
        getItemList();
        getMethodList();
        getEventList();
    }, []);

    const getSessionList =  async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=session_master&condition=status=1`);
        const sessionList = response.data.data;
        sessionList.unshift({id:"", name:"Session List"});
        setSessionList(sessionList);
    }

    const getItemList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=item_master&condition=status=1`);
        const itemList = response.data.data;
        // itemList.unshift({id:"", item_name:'Item List'});
        if(routeName == "rotlibhakhri"){
            setItemList(itemList.filter((item) => item.category == 3))
        }else{
            setItemList(itemList);
        }
    }

    const getMethodList = async () => {
        const response = await axios.get(`${baseUrl}/comman/list?table=method_master&condition=status=1`);
        const methodList = response.data.data;
        // methodList.unshift({id:"", name:'Method List'});
        setMethodList(methodList);
        
        if(routeName == "rotlibhakhri"){
            setMethod(methodList.filter((item) => item.id == 3).map((item) => {
                return {
                    value : item.id,
                    label: item.name
                }
            }))
        }else{
            setMethod(methodList.filter((item) => item.id == 1 || item.id == 3).map((item) => {
                return {
                    value : item.id,
                    label: item.name
                }
            }))
        }
        // if ((routeName === "excess" || routeName === "production") && methodList.length >= 3) {
        //     setMethod([methodList[0], methodList[2]].map(item => ({
        //         value: item.id,
        //         label: item.name
        //     })));
        // }
    }

    const getEventList = async() => {
        const response = await axios.get(`${baseUrl}/comman/list?table=event_master&condition=status=1`);
        const data = response.data.data;
        const newObject = { id: 'all', event_name: 'All' };
        data.unshift(newObject);
        setEventList(data);
        setEvent({ value: 'all', label: 'All' })
    }

    const handleDateChange = (e) => {
        const {name, value} = e.target;
        if(name == "from_date"){
            setFromDate(value);
        }else if(name == "to_date"){
            setToDate(value);
        }
        
    };

    const handleSelectChange = (data, option) => {
        if(option.name == 'session'){
            setSession(data);
        }else if(option.name == 'method'){
            setMethod(data);
        }else if(option.name == 'reportType'){
            setReportType(data);
        }
        
    };

    const handleSubmit = () => {
        // Handle submit logic here
        // You can pass date and session values to the parent component using handleFilterChange
        handleFilterChange(fromDate, toDate, session, item, method);
        setFilter(true);
    };

    const handleClear = () => {
        handleFilterChange("", "");
        setSession("");
        setFromDate("");
        setItem("");
        setMethod("");
        setFilter(false);
    }

    // const handleSummary = () => {
    //     setSummary(!summary);
    //     hadelSummary(!summary);
    // }

    const handleReport = (data) => {
        setReportType(data);
        handelReportChange(data);
    }

    const clickExport = () => {
        handleExport();
    }

    const handleChangeMultiSelect = (selected, name) => {
        if(name === 'item'){
            setItem(selected);
        }else if(name === 'method'){
            console.log(selected);
            setMethod(selected);
        }
        
    };

    const handelEventChange = (item) => {
        // console.log(eventList);
        const data = eventList.filter(i => i.id == item.value);
        setEvent({value: data[0].id, label: data[0].event_name});
        // console.log(data[0].start_date);
        setFromDate(data[0].start_date);
        setToDate(data[0].end_date);
        handleFilterChange(data[0].start_date, data[0].end_date, session, item, method);
    }

    return (
        <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center pb-4">
            <div className='m-5'>
            <Select
                    
                    classNamePrefix="select"
                    className='basic-multi-select'
                    value={event}
                    name="event_name"
                    onChange={(selectedOptions) => handelEventChange(selectedOptions)}
                    options={eventList.map(item => ({
                        value: item.id,
                        label: item.event_name
                    }))}
                    placeholder="Event List"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
            </div>
            <div className='m-5'>
                <input
                    type="date"
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="from_date"
                    onChange={handleDateChange}
                    value={fromDate}
                />
            </div>
            <div className='m-5'>
                <input
                    type="date"
                    className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="to_date"
                    onChange={handleDateChange}
                    value={toDate}
                />
            </div>
            <div className='m-5'>
                <Select
                    classNamePrefix="select"
                    value={session}
                    name="session"
                    onChange={(selectedOption, name) => {
                        handleSelectChange(selectedOption, name);
                    }}
                    options={sessionList.map(item => ({
                        value: item.id,
                        label: item.name
                    }))}
                    placeholder="Session List"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
            </div>
            <div className='m-5'>
                <Select
                    isMulti
                    classNamePrefix="select"
                    className='basic-multi-select'
                    // value={item}
                    name="item"
                    onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions, 'item')}
                    options={itemList.map(item => ({
                        value: item.id,
                        label: `${item.item_name} ${item.eng_name ? `(${item.eng_name})` : ''}`
                    }))}
                    placeholder="Item List"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
            </div>
            {(routeName == "rotlibhakhri") ? 
            <div className='m-5'>
                <Select
                    isMulti
                    classNamePrefix="select"
                    className='basic-multi-select'
                    value={method}
                    name="method"
                    isDisabled
                    onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions, 'method')}
                    options={methodList.map(item => ({
                        value: item.id,
                        label: item.name
                    }))}
                    placeholder="Method List"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
            </div> :
            <div className='m-5'>
                <Select
                    isMulti
                    classNamePrefix="select"
                    className='basic-multi-select'
                    value={method}
                    name="method"
                    onChange={(selectedOptions) => handleChangeMultiSelect(selectedOptions, 'method')}
                    options={methodList.map(item => ({
                        value: item.id,
                        label: item.name
                    }))}
                    placeholder="Method List"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
            </div>}
            
            <div className='m-5'>
                <button
                    onClick={handleSubmit}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                    Submit
                </button>
                {filter && 
                 <button
                 onClick={handleClear}
                 className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
             >
                 Clear
             </button>}
            </div>
            {/* {routeName != "summary" && <div>
                <button
                    onClick={handleSummary}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                    {!summary ? 'Summary' : 'Detail'}
                </button>
            </div>} */}
            <div>
                <button
                    onClick={clickExport}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200"
                >
                    Export
                </button>
            </div>
            {(routeName == "master") && <div className='m-5'>
                <Select
                    classNamePrefix="select"
                    value={reportType}
                    name="reportType"
                    onChange={(selectedOption, name) => {
                        // handleSelectChange(selectedOption, name);
                        handleReport(selectedOption);
                    }}
                    options={reportTypeList.map(item => ({
                        value: item.id,
                        label: item.label
                    }))}
                    placeholder="Report List"
                    filterOption={(option, inputValue) => {
                        return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                    }}
                />  
            </div>}
        </div>
    );
};

export {DateSessionReportFilter};
